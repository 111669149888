@charset "UTF-8";
/* CSS Document */
/*--------------------
00.top
01.login
02.signup
16.アカウント情報
17.ユーザー情報
30.サービス利用状況
41.プライバシーポリシー
42.利用規約
43.クッキーポリシー
44.問い合わせフォーム
45.問い合わせフォーム（確認画面）
46.問い合わせフォーム（完了画面）
45_サービス利用状況
25_ログイン後トップ
6_ログイン画面 – パスワード設定用メール送信確認
メールアドレス変更（本人確認）
ログイン後アカウント画面 - メールアドレス変更（メール送信）
8_入力内容確認
20.signup
アカウント情報 - セキュリティ
ログイン履歴
新規会員登録 - アカウント登録
新規会員登録 - アカウント登録（メールアドレスで登録）
新規会員登録 – メール送信(認証番号)
新規会員登録 - アカウント登録（SNSで登録）
ログイン画面 – パスワード不明
ログイン画面 – パスワード再設定
ログイン画面 – パスワード再設定完了
ログイン後アカウント画面 - パスワード変更
ログイン後アカウント画面 - サービスの利用状況（使用開始）
ログイン後アカウント画面 - サービスの利用状況（使用停止）
アカウントの削除
アカウント削除完了
全エラー画面共通
FAQ
会社概要
メールアドレス変更（確認完了）
--------------------*/
/*----------
00.top
----------*/

#top .bg{
background-image: url("../img/top/mv01.png");
background-repeat: no-repeat;
background-size: 117.5%;
background-position: -159.8px 90.2px;
}

#top .cnt_area00 .contents_in{
max-width: 1080px;
}

/*----------
01.login
----------*/
#login{
margin-top: 82px;
}
#login .cnt_box{
display: flex;
justify-content: space-between;
margin: 0 auto;
padding: 50px 0;
}
#login .box_l{
display: flex;
flex-direction: column;
justify-content: space-between;
max-width: 524px;
width: 49%
}

#login .box_l .text{
max-width: 155px;
margin: 0 auto;
}

#login .btn_ptn01.sp{
display: none;
}
#login .btn_ptn01.pc{
display: flex;
margin: 0 auto;
}
#login .box_l .btn_ptn01{
margin: 0 auto;
max-width: 394px;
width: 100%;
}
#login .box_l .btn_ptn01::after{
width: 65px;
}

#login .btn_ptn01 a{
font-size: 16px;
}
#login .box_r{
/*
border-radius: 20px;
padding: 40px;
max-width: 476px;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
box-sizing: border-box;
*/
margin: 0;
width: 49.2%;
}
#login .box_r .btn_box{
margin: 0 0 25px;
border-bottom: 1px solid #D1D1D1;
padding: 0 0 15px;
}
#login .box_r .text{
margin: 0 0 25px;
font-size: 18px;
color: #4D4D4D;
font-weight: 700;
text-align: center;
}
#login .btn_box + .text{
margin: 0 0 15px;
}
#login .box_r .btn_ptn03{
margin: 0 auto 15px;
max-width: 353px;
width: 100%;
}


#login .form_text{
margin-bottom: 4px;
font-size: 14px;
}
#login .form_g dd{
margin-bottom: 15px;
}

#login .recaptcha{
margin: 24px auto 25px;
}
#login .recaptcha div{
margin: 0 auto;
}


/*----------
02.signup
----------*/

#signup {
position: relative;
margin-top: 82px;
}

#signup::before{
content: '';
display: block;
width: 100%;
height: 53.59vw;
background: url("../img/common/bgimg02.png") no-repeat center top/100% auto;
background-color: #168AC7;
position: absolute;
top: 0;
right: 0;
z-index: -1;
}

#signup .text_box_ptn01.text_box p{
text-align: center;
margin-bottom: 30px;
}

#signup .page_title_ptn01.white h1 span{
display: inline-block;
max-width: 136.44px;
}

#signup .cnt_area{
/* background: #fff;
display: block;
border-radius: 20px 20px 0 0;
max-width: 800px;
margin: 0 auto;
padding: 53px 40px 40px; */
z-index: 1;
position: relative;
}

#signup .text_box h2{
margin-bottom: 20px;
font-size: 18px;
line-height: 27px;
font-weight: 700;
color: #168AC7;
text-align: center;
}
#signup .text_box p{
text-align: center;
margin-bottom: 40px;
}

#signup .form_g {
  margin-bottom: 60px;
}

#signup .btn_g{
display: flex;
justify-content: space-between;
flex-flow: wrap;
max-width: 423px;
}

#signup .btn_g .btn_ptn01{
width: 48%;
max-width: 200px;
margin-bottom: 15px;
}
#signup .btn_g .btn_ptn01:hover{
background: #fff;
}

#signup .btn_g .btn_ptn01 a{
width: 100%;
font-size: 16px;
height: 52px;
padding: 0 37px 0 0;
display: flex;
justify-content: center;
align-items: center;
flex-flow: column;
}
#signup .btn_g .btn_ptn01 a:hover{
color: #168AC7;
}
#signup .btn_g .btn_ptn01 a::after{
width: 37px;
}
#signup .btn_g .btn_ptn01 a:hover::after{
background: transparent linear-gradient(137deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
#signup .btn_g .btn_ptn01 a::before{
right: 16px;
}
#signup .btn_g .btn_ptn01 span{
width: 84px;
display: block;
margin: 0 auto;
}
#signup .btn_g .btn_ptn01 span.small{
font-size: 10px;
margin-top: 6px;
}
#signup .privacy_box{
margin-bottom: 40px;
}

/*--------------------
16.アカウント情報
--------------------*/
#account_info .mail_box{
width: 100%;
background: #F2F4F5;
border-radius: 5px;
padding: 11px 11px 13px;
}

#account_info .mail_box .email{
font-size: 16px;
color: #707070;
line-height: 24px;
display: inline-block;
margin-right: 60px;
}

#account_info .user_img{
display: flex;
justify-content: flex-start;
align-items: flex-end;
}

#account_info .user_img .img{
display: block;
position: relative;
width: 80px;
height: 80px;
border-radius: 50%;
overflow: hidden;
margin-right: 28px;
}
#account_info .user_img .img img{
display: block;
border-radius: 50%;
object-fit: cover;
width: 80px;
height: 80px;
}
#account_info .user_img p{
width: 100%;
font-size: 14px;
color: #707070;
margin-top: 10px;
}

#account_info input.name{
max-width: 404px;
}
#account_info .btn_submit{
max-width: 240px;
margin: 40px auto 0 0;
}

#account_info .cnt_img{
width: 8vw;
position: fixed;
bottom: 174.43px;
right: 6vw;
z-index: 2;
}

#account_info.setting_page .main_contents{
padding-bottom: 160px;
}

#account_info .form_g .country .cursor{
margin: 0;
}
#account_info .form_g .country .child_box{
max-width: 404px;
}
#account_info .user_img .text{
width: calc(100% - 108px);
}

#account_info .bthacks_profile{
display: flex;
align-items: center;
margin-bottom: 3.25rem;
}
#account_info .bthacks_profile img{
height: 3rem;
width: 6.5rem;
margin-right: 14.75rem;
}
#account_info .bthacks_profile a{
width: 6.25rem;
}

/*--------------------
17.ユーザー情報
--------------------*/

#user_info .main_contents{
height: 100vh;
}

#user_info .country_g {
  margin-bottom: 30px;
}

#user_info .country_g dd{
display: flex;
align-items: center;
width: 224px;
}

#user_info .country_g dd label{
font-size: 14px;
line-height: 19px;
width: 47px;
}

#user_info .country_g dd select{
height: 40px;
max-width: 177px;
padding: 7px 16px;
cursor: pointer;
}

/*
#user_info.setting_page .main_contents .line {
margin: 14px 0 32px;
}
*/

#user_info .country_g dd{
display: flex;
width: 224px;
}

#user_info .keyword_g{
margin-bottom: 25px;
}

#user_info .btn_submit{
max-width: 240px;
margin: 0;
}

#user_info .btn_submit input[type="submit"]{
padding: 11px 0;
}

#user_info .cnt_img{
width: 4.75vw;
position: fixed;
/*bottom: 37px;*/
bottom: 174.43px;
right: 7vw;
}
#user_info .h2_ptn01 + p{
margin-bottom: 30px;
font-size: 14px;
line-height: 24px;
}
/*----------------
30.サービス利用状況
-----------------*/
#status .main_contents h2.h2_ptn01{
margin-bottom: 50px;
}
#status .lead{
line-height: 1.4;
font-weight: 400;
margin-bottom: 30px;
}
#status h3{
font-weight: 700;
font-size: 18px;
line-height: 27px;
margin-bottom: 20px;
}

#status .cnt_g{
margin-bottom: 40px;
}


#status .cnt_g:nth-of-type(3){
margin-bottom: 35px;
}

#status table{
width:100%;
table-layout: fixed;
word-break: break-word;
}


#status table .item{
color: #707070;
font-size: 14px;
line-height: 24px;
}

#status table th:nth-of-type(1){
padding: 14px 10px 12px;
text-align: left;
width: 65.4%;
}

#status table .service th a{
color: #168AC7;
font-size: 18px;
font-weight: 500;
line-height: 26px;
text-decoration: underline;
}

#status table .item th:nth-of-type(2),
#status table td:nth-of-type(1){
color: #707070;
text-align: center;
width: 18%;
}

#status table td:nth-of-type(1){
color: #707070;
font-size: 18px;
line-height: 31px;
padding: 11px 10px;
}

#status table .item th:nth-of-type(3),
#status table td:nth-of-type(2){
padding: 8px 0;
text-align: left;
width: 16.5%;
}

#status table tr{
border-bottom: 1px solid #D3D3D3;
}

#status .btn_submit{
max-width: 120px;
margin: 0 0 0 auto;
}

#status .btn_submit input[type="submit"]{
line-height: 23px;
padding: 6px 0;
}

#status .link a{
color: #168AC7;
font-size: 16px;
line-height: 27px;
}

#status.setting_page .main_contents{
padding-bottom: 120px;
}

#status .cnt_img{
width: 4.75vw;
position: absolute;
bottom: 24px;
right: 7vw;
max-width: 60px;
z-index: 1;
}

#status .contents_in{
position: relative;
z-index: 2;
}









/*------------------
41.プライバシーポリシー
-------------------*/

.public_page .page_contents{
padding: 60px 0 39px;
}
.public_page .contents_in {
max-width: 860px;
}
.public_page h1{
color: #168AC7;
font-size: 40px;
font-weight: 700;
line-height: 65px;
letter-spacing: 0.05em;
margin: 0 auto 20px;
text-align: center;
}

/*ポリシーページ共通CSS*/
.policy_page h2{
color: #168AC7;
font-size: 18px;
font-weight: 500;
line-height: 26px;
margin: 0 auto 20px;
padding-bottom: 9px;
position: relative;
}


.policy_page h2::before{
position: absolute;
bottom: 0;
left: 0;
width: 24px;
height: 1px;
background: #168AC7;
content: '';
}

.policy_page p{
color: #4A4A4A;
font-size: 15px;
line-height: 32px;
margin: 0 auto 40px;
}

/*END　ポリシーページ共通CSS*/

/*------------------
42.利用規約
-------------------*/
/*------------------
43.クッキーポリシー
-------------------*/
.align_right {
text-align: right;
}

.list-group-reset {
list-style: none;
counter-reset: custom-counter;
padding-left: 0;
}

.list-item-parenthesis {
text-indent: -44px;
padding-left: 44px;
}

.list-item-parenthesis::before {
content: "（" counter(custom-counter) "）";
counter-increment: custom-counter;
padding-right: 5px;
}

.list-item-parenthesis-v2 {
text-indent: -49px;
padding-left: 49px;
}

.list-item-parenthesis-v2::before {
content: "（" counter(custom-counter) "）";
counter-increment: custom-counter;
padding-right: 5px;
}

.list-item-decimal {
text-indent: -13px;
padding-left: 13px;
}

.list-item-decimal::before {
content: counter(custom-counter) ".";
counter-increment: custom-counter;
}

.list-item-decimal-no-period::before {
content: counter(custom-counter);
counter-increment: custom-counter;
margin-left: 46px;
margin-right: 5px;
}

/*------------------
44.問い合わせフォーム
-------------------*/

/*お問い合わせページ共通CSS*/

.contact_page .page_contents {
padding: 60px 0 120px;
}

.contact_page p{
letter-spacing: 0.03em;
text-align: center;
}

/*END お問い合わせページ共通CSS*/


#contact .text_box_ptn01{
max-width: 860px;
margin: 60px auto 0;
padding: 80px;
}

#contact .form_text {
line-height: 22px;
}

#contact .form_g{
color: #4D4D4D;
}

#contact .form_g dd.email_text input{
max-width: 350px;
}

#contact .form_g select{
color: #707070;
max-width: 390px;
}

#contact .recaptcha{
margin: 30px auto;
max-width: 304px;
}

/*-------------------------
45.問い合わせフォーム（確認画面）
--------------------------*/

#contact_confirm .form_box_ptn02{
max-width: 860px;
margin: 60px auto 0;
padding: 80px;
}
#contact_confirm .form_box_ptn02 dl{
color: #4D4D4D;
}

#contact_confirm .form_box_ptn02 dd {
color: #4D4D4D;
margin: 0 0 38px;
font-size: 16px;
line-height: 22px;
}

#contact_confirm .form_box_ptn02 .privacy_box {
margin: 0 0 50px;
padding-top: 6px;
}

#contact_confirm .form_box_ptn02 .btn_submit {
max-width: 353px;
margin: 0 auto 26px;
}


/*-------------------------
46.問い合わせフォーム（完了画面）
--------------------------*/

#contact_done .page_contents{
position: relative;
}

#contact_done .cnt_img{
width: 7.8vw;
position: absolute;
bottom: 39px;
right: 8.59vw;
}

#contact_done p{
color: #4A4A4A;
line-height: 28px;
letter-spacing: 0.03em;
margin-bottom: 62px;
text-align: center;
}

#contact_done .lead{
font-size: 16px;
font-weight: 500;
margin-bottom: 64px;
}

#contact_done .btn_ptn02{
max-width: 220px;
margin: 0 auto;
}

#contact_done .btn_ptn02 a{
padding: 19px 0;
}


/*----------
25_ログイン後トップ
----------*/
header.login{
z-index: 10;
}
#first_login{
display: block;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
background: rgba(0, 0, 0, 0.6);
z-index: 10;
}
#first_login .popup{
display: block;
/* max-width: 1080px; */
width: 92%;
background: #fff;
margin: 0 auto;
position: absolute;
top: 50%;
left:0;
right: 0;
transform: translateY(-50%);
/* padding: 10vh 80px; */
border-radius: 20px;
/* height: 85vh; */

max-width: 1000px;
height: 70vh;
padding: 26px 40px;
}
#first_login .btn_close{
width: 44px;
height: 44px;
background: rgb(13,87,160);
background: -webkit-linear-gradient(218deg, rgba(13,87,160,1) 0%, rgba(22,138,199,1) 100%);
background: -o-linear-gradient(218deg, rgba(13,87,160,1) 0%, rgba(22,138,199,1) 100%);
background: linear-gradient(308deg, rgba(13,87,160,1) 0%, rgba(22,138,199,1) 100%);
border-radius: 50%;
margin: 30px auto;
position: absolute;
margin: 0;
top: 40px;
right: 40px;
cursor: pointer;
}
#first_login .btn_close::before,
#first_login .btn_close::after{
content: '';
display: block;
width: 2px;
height: 24px;
background: #fff;
position: absolute;
top: 50%;
left: 0;
right: 0;
margin: 0 auto;
transform-origin: center;
}
#first_login .btn_close::before{
transform: translateY(-50%) rotate(45deg);
}
#first_login .btn_close::after{
transform: translateY(-50%) rotate(-45deg);
}
#first_login h2{
font-weight: 700;
color: #168AC7;
font-size: 40px;
line-height: 58px;
margin-bottom: 20px;
}
#first_login p{
font-weight: 500;
line-height: 28px;
font-size: 16px;
margin-bottom: 25px;
}
#first_login .box{
padding: 10px;
border: 1px solid #D1D1D2;
border-radius: 10px;
height: calc( 100% - 155px);
}
#first_login .box .inner{
display: block;
height: 100%;
overflow-y: scroll;
/* padding: 20px; */

}
#first_login .box .inner::-webkit-scrollbar {
width: 10px;
}

/*スクロールバーの軌道*/
#first_login .box .inner::-webkit-scrollbar-track {
border-radius: 10px;
background: #D1D1D2;
}

/*スクロールバーの動く部分*/
#first_login .box .inner::-webkit-scrollbar-thumb {
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
border-radius: 10px;
}

#first_login .keyword_g,
#first_login .country_g{
/* margin-bottom: 30px; */
margin-bottom: 20px;
}
#first_login .keyword_g .form_text,
#first_login .country_g .form_text{
font-weight: 500;
}
#first_login .country_g .form_text span{
font-weight: 400;
line-height: 28px;
}
#first_login .btn_g{
display: flex;
justify-content: flex-start;
align-items: flex-start;
}
#first_login .btn_g .btn_submit{
max-width: 200px;
margin: 0 0 0 15px;
}
#first_login .btn_g .btn_ptn02 a{
padding: 19px 0;
}

#login_top .cnt_outwrap{
display: flex;
margin: 0 auto;
}
#login_top .main_box{
position: relative;
padding: 69px 0 130px;
width: 70%;
}
#login_top .main_box::before{
content: '';
display: block;
position: absolute;
width: 100%;
height: 50%;
background-image: url(../img/login_top/bg01.png);
background-size: 100% auto;
background-repeat: no-repeat;
background-position: 0 0;
top: 0;
left: 0;
}
#login_top .main_box::after{
content: '';
display: block;
position: absolute;
width: 100%;
height: 16%;
bottom: 0;
left: 0;
background: transparent linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(189, 223, 240, 1) 30%, rgba(22, 136, 197, 1) 65%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
z-index:-1;
}
#login_top .main_box .inner_wrap{
position: relative;
margin: 0 auto;
width: 90%;
z-index: 1;
}
#login_top .main_box .g01,
#login_top .main_box .g02{
padding: 28px 4% 54px;
background-color: #fff;
}
#login_top .main_box .g02 {
  padding-top: 0;
}
#login_top .g01 .title_area{
display: flex;
margin: 0 8px 33px auto;
align-items: center;
justify-content: space-between;
}
#login_top .g01 .box_title{
display: flex;
align-items: center;
font-size: 22px;
font-weight: 700;
color: #168AC7;
letter-spacing: 0.05em;
/*line-height: 65px;*/
}
#login_top .g01 .box_title::before{
content: '';
display: inline-block;
margin: 0 14.5px 0 0;
width: 19px;
height: 37px;
background-image: url(../img/login_top/title1.png);
background-size: contain;
background-repeat: no-repeat;
}
#login_top .g01 .link a{
position: relative;
border-bottom: 1px solid #168AC7;
font-size: 16px;
letter-spacing: 0.05em;
line-height: 24px;
color: #168AC7;
}
#login_top .g01 .link a::before{
content: '';
display: inline-block;
width: 14px;
height: 14px;
background-image: url(../img/login_top/link_parts.png);
background-size: contain;
background-repeat: no-repeat;
}
#login_top .g01 .link a::after{
content: '';
display: block;
position: absolute;
width: 6px;
height: 9px;
background-image: url(../img/login_top/link_parts2.png);
background-size: contain;
background-repeat: no-repeat;
top: 40%;
right: -12px;
}
#login_top .g01 .cnt_box a{
display: flex;
margin: 0 0 22.5px;
padding: 0 2.5% 22.5px;
border-bottom: 1px solid #D2DDE5;
}
#login_top .g01 .cnt_box:last-of-type a{
margin: 0;
padding-bottom: 0;
border: none;
}
#login_top .g01 .cnt_img{
position: relative;
border-radius: 10px;
width: 28%;
}
#login_top .g01 .cnt_img{
transition: 0.5s;
}
#login_top .g01 .cnt_img.new::before{
content: 'NEW!';
display: block;
position: absolute;
border-radius: 10px;
padding: 1px 0;
width: 57px;
font-size: 14px;
font-weight: 500;
line-height: 20px;
color: #fff;
background-color: #168AC7;
text-align: center;
box-shadow: rgba(0, 0, 0, 0.09) 0 0 10px;
top: 0;
right: 0;
z-index: 1;
}
#login_top .g01 .data{
padding: 0 0 0 3%;
width: 70%;
}
#login_top .g01 .data ul{
display: flex;
margin: 0 0 12px;
}
#login_top .g01 .data li::before{
content: '';
display: inline-block;
margin: 0 10.5px 0 0;
width: 15px;
height: 16px;
background-image: url(../img/login_top/tag.png);
background-size: contain;
background-repeat: no-repeat;
vertical-align: middle;
}
#login_top .g01 .data span{
padding: 0 3px;
font-size: 13px;
letter-spacing: 0.03em;
line-height: 28px;
color: #969696;
background-color: #EAF7FF;
}
#login_top .g01 h3{
font-size: 16px;
font-weight: 700;
letter-spacing: 0.03em;
line-height: 27px;
transition: 0.3s;
}
#login_top .g01 a:hover img{
opacity: 0.7;
}
#login_top .g01 a:hover h3{
color: #168AC7;
}
#login_top .g01 .tagempty_text,
#login_top .g02 .cnt_box {
display: flex;
align-items: center;
border-radius: 15px;
margin: 0 -1.2% 0 2.7%;
background-color: #fff;
box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
}
#login_top .g01 .tagempty_text {
  min-height: 200px;
  padding: 20px 40px;
  /*margin:0 -1.2% 0 2.7%;*/
}
#login_top .g01 .tagempty_text .text {
  display: block;
  margin:0 auto;
}
#login_top .g01 .tagempty_text .text p {
  font-size: 16px;
  font-weight: bold;
}
#login_top .g01 .tagempty_text .link {
  display:inline-block;
}
#login_top .g01 .cnt_box:last-of-type .tagempty_text a {
  margin: 20px 0 0;
  padding: 0;
  line-height: 14px;
}

#login_top .g02 .cnt_img{
width: 30%;
}
#login_top .g02 .cnt_img img{
border-radius: 15px 0 0 15px;
}
#login_top .g02 .cnt_text{
padding: 0 0 0 31px;
font-size: 20px;
font-weight: 400;
letter-spacing: 0.06em;
line-height: 30px;
color: #168AC7;
width: 70%;
}
#login_top .g02 .cnt_text span{
font-weight: 700;
}

#login_top .gray_box{
padding: 30px 5% 35px;
border: 1px solid #D1D1D1;
border-radius: 20px;
background-color: #F8F8F8;
}
#login_top .gray_box .service_title{
border-bottom: 1px solid #D3D3D3;
margin: 0 0 30px;
padding: 0 0 20px;
font-size: 20px;
font-weight: 700;
letter-spacing: 0.05em;
}
#login_top .gray_box li{
display: flex;
margin: 0 0 30px;
border-bottom: 1px solid #D3D3D3;
padding: 0 0 30px;
}
#login_top .gray_box .cnt_img{
width: 25%;
}
#login_top .gray_box .service_text{
text-align: center;
font-size: 26px;
font-weight: 700;
letter-spacing: 0.05em;
line-height: 36px;
color: #168AC7;
text-align: center;
}
#login_top .gray_box .text_box{
padding: 0 0 0 30px;
width: 75%;
}
#login_top .gray_box .cnt_text{
margin: 0 0 22px;
}
#login_top .gray_box .cnt_text p{
font-size: 15px;
font-weight: 400;
line-height: 26px;
}

#login_top .gray_box .btn a{
display: inline-block;
border-radius: 8px;
padding: 13px 20px;
font-size: 16px;
font-weight: 500;
letter-spacing: 0.05em;
line-height: 24px;
color: #168AC7;
background-color: #fff;
box-shadow: rgba(0, 0, 0, 0.09) 0 0 10px;
transition: all 0.3s;
}
#login_top .gray_box .btn a:hover{
color: #fff;
background-color: #168AC7;
}
#login_top .gray_box .btn a::after{
content: '';
display: inline-block;
margin: 0 0 0 10px;
width: 12px;
height: 12.5px;
background-image: url(../img/login_top/btn_parts.png);
background-size: contain;
background-repeat: no-repeat;
}
#login_top .gray_box .btn a:hover:after{
background-image: url(../img/login_top/btn_parts_h.png);
}
#login_top .gray_box .btn_g{
display: flex;
flex-wrap: wrap;
}
#login_top .gray_box .btn_g .btn{
margin: 0 10px 20px 0;
}
#login_top .g03{
margin: 0 2.7% 30px 6.4%;
}
#login_top .g04{
margin: 0 2.7% 0 6.4%;
}
#login_top .box_r{
width: 30%;
box-shadow: rgba(0,0,0,0.08) 0 0 15px;
}
#login_top .box_r .cnt_wrap{
margin: 0 15.66667% 0 30px
}
#login_top .cnt_rbox h2{
display: flex;
align-items: center;
margin: 0 15.66667% 0 30px;
font-size: 20px;
font-weight: 700;
letter-spacing: 0.05em;
line-height: 65px;
color: #168AC7;
}
#login_top .cnt_rbox h2::before{
content: '';
display: inline-block;
margin: 0 16px 0 0;
width: 14px;
height: 34px;
background-image: url(../img/login_top/pic12.png);
background-size: contain;
background-repeat: no-repeat;
}
#login_top .r_box01{
padding: 38px 0 23px;
background-color: #F9FBFE;
}
#login_top .r_box01 ul{
display: flex;
flex-wrap: wrap;
margin: 0 0 0 -5px;
}
#login_top .r_box01 .service{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
position: relative;
margin: 0 0 0 5px;
padding: 0 4px;
width: 63px;
height: 63px;
background: transparent linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(239, 239, 239, 1) 100%) 0% 0% no-repeat padding-box;
}
#login_top .r_box01 .service.off::before{
content: '';
display: block;
position: absolute;
border-radius: 5px;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.3);
top: 0;
left: 0;
}
#login_top .r_box01 .cnt_text{
padding: 0;
font-size: 12px;
font-weight: 700;
line-height: 17px;
color: #168AC7;
text-align: center;
z-index: 1;
}
#login_top .r_box01 .cnt_text .tts{
font-size: 14px;
line-height: 20px;
}
#login_top .r_box01 .cnt_text span{
font-size: 10px;
line-height: 15px;
}
#login_top .r_box02{
padding: 45px 0 0;
}
#login_top .r_box02 h2{
margin-bottom: 15px;
border-bottom: 1px solid rgba(0,0,0,0.5);
padding: 0 0 20.5px;
}
#login_top .r_box02 h2::before{
margin: 0 5px 0 0;
border-radius: 5px;
width: 20px;
height: 34px;
background-image: url(../img/login_top/pic09.png);
}
#login_top .r_box02 li{
margin: 0 0 15px;
padding: 0 0 15px;
border-bottom: 1px solid rgba(0,0,0,0.5);
}
#login_top .r_box02 li a{
font-size: 14px;
font-weight: 400;
line-height: 24px;
transition: 0.2s;
}
#login_top .r_box02 li a:hover{
color: #168AC7;
}
#login_top .r_box02 .link{
text-align: right;
}
#login_top .r_box02 .link a{
display: inline-flex;
align-items: center;
font-size: 15px;
font-weight: 500;
letter-spacing: 0.05em;
line-height: 21px;
color: #3C3C3C;
transition: 0.3s;
}
#login_top .r_box02 .link a::after{
content: '';
display: inline-block;
margin: 0 0 0 8px;
width: 5px;
height: 21px;
background-image: url(../img/login_top/pic13.png);
background-size: contain;
background-repeat: no-repeat;
background-position: center;
}
#login_top .r_box02 .link a:hover{
text-decoration: underline;
}
/*----------
6_ログイン画面 – パスワード設定用メール送信確認
----------*/
#password_confirm_mail main{
position: relative;
padding: 60.5px 0 33px;
}
#password_confirm_mail main::after{
content: '';
display: block;
position: absolute;
width: 100%;
height: 229px;
background-color: #168AC7;
bottom: 0;
left: 0;
z-index: -1;
}
#password_confirm_mail .title{
margin: 0 auto 20px;
text-align: center;
color: #168AC7;
}
#password_confirm_mail .lead{
margin: 0 auto 39px;
text-align: center;
}
#password_confirm_mail .cnt_text{
margin:  0 auto 24.5px;
text-align: center;
font-size: 15px;
letter-spacing: 0.03em;
line-height: 28px;
}
#password_confirm_mail .cnt_img{
margin: 0 auto 30px;
width: 300px;
}
#password_confirm_mail .btn_ptn02{
margin: 0 auto;
max-width: 220px;
}
#password_confirm_mail .btn_ptn02 a{
height: 54px;
width: 220px;
}
/*----------
メールアドレス変更（本人確認）
----------*/
.mail_change{
position: relative;
margin: 130px auto 80px;
width: 100%;
max-width: 860px;
}
.mail_change .decoration_img{
width:80px;
position: absolute;
bottom: 0;
right: 0;
}
.mail_change .contents_in{
width: 95%;
}
.mail_change .contents_in .back{
margin: 0 0 10px;
}
.mail_change .contents_in .back a{
border-bottom: 1px solid #168AC7;
color: #168AC7;
font-size: 14px;
font-weight: 400;
}
.mail_change .form_box_ptn02{
max-width: none;
}
.mail_change .title{
margin: 0 0 20px;
color: #168AC7;
text-align: center;
}
.mail_change .lead{
margin: 0 0 30px;
line-height: 28px;
text-align: center;
}
.mail_change .mail{
margin: 0 0 30px;
line-height: 28px;
text-align: center;
}
.mail_change .form{
margin: 0 auto;
max-width: 350px;
}
.mail_change .form_box_ptn02 .pas{
margin: 15px 0 5px;
}
.mail_change .form_box_ptn02 .btn_submit{
margin: 0 auto 15px;
max-width: 220px;
}
.mail_change .form_box_ptn02  .btn_submit button{
padding: 18px 0;
}
.mail_change .form_box_ptn02 .btn_ptn02{
margin: 0 auto;
max-width: 220px;
}
.mail_change .form_box_ptn02 .btn_ptn02 a{
padding: 22px 0;
}
.mail_change .form_box_ptn02 dd{
padding: 0;
}
/*----------
メールアドレス変更（本人確認）
----------*/
/*----------
メールアドレス変更
----------*/
#new_mail .decoration_img{
width: 110px;
}
/*----------
ログイン後アカウント画面 - メールアドレス変更（メール送信）
----------*/
.mail_change_ptn02.signup_bg::before{
background-image: none;
background-color: #fff;
}
.mail_change_ptn02 .contents_in{
position: relative;
max-width: 860px;
}
.mail_change_ptn02 .form_box_ptn02{
max-width: none;
}
.mail_change_ptn02 .cnt_text{
margin: 0 auto 30px;
text-align: center;
}
.mail_change_ptn02 .btn_ptn03{
margin: 0 auto;
max-width: 234px;
}
.mail_change_ptn02 .decoration_img{
width: 80px;
position: absolute;
bottom: -20px;
right: -20px;
}

/*----------
ログイン後アカウント画面 - パスワード変更
----------*/
#new_password .form_g{
margin: 0 auto 40px;
}
#new_password input[type="password"] + #btn_passview2{
margin: 16px 15px 16px 0;
width: 23px;
height: 15px;
background-image: url(../img/common/pas_icon.png);
background-size: contain;
background-repeat: no-repeat;
}
#new_password #btn_passview2{
margin: 16px 15px 16px 0;
width: 23px;
height: 15px;
background-image: url(../img/common/pas_icon2.png);
background-size: contain;
background-repeat: no-repeat;
}
#new_password .input_pass{
margin: 0;
border: none;
padding: 11px 0 13px 16px;
background: rgba(255,255,255,0);
outline: none;
width: calc(100% - 18px);
}
#new_password .link{
margin: 50px auto 0;
text-align: center;
}
#new_password .link a{
text-decoration: underline;
}
#new_password .decoration_img{
width: 110px;
}
/*----------
ログイン後アカウント画面 - サービスの利用状況（使用開始）
----------*/
#status_start{
margin-top: 82px;
}

#status_start .together{
margin: 0 auto 21px;
font-size: 18px;
font-weight: 700;
text-align: center;
}
#status_start.signup_bg::after{
content: none;
}
#status_start h1{
margin-bottom: 30px;
text-align: center;
}
/*----------
ログイン後アカウント画面 - サービスの利用状況（使用停止）
----------*/
#status_stop{
margin-top: 82px;
}

#status_stop .together{
margin: 0 auto 21px;
font-size: 18px;
font-weight: 700;
text-align: center;
}
#status_stop.signup_bg::after{
content: none;
}
#status_stop h1{
margin-bottom: 30px;
text-align: center;
}
/*----------
2段階認証済み
----------*/
#two_step_login{
margin-top: 82px;
}

#two_step_login .first_text{
margin: 0 auto 30px;
text-align: center;
}
#two_step_login .form{
margin: 0 auto 30px;
}
#two_step_login .form_g{
margin: 0 auto 52px;
max-width: 353px;
}
#two_step_login .btn_submit{
max-width: 234px;
}
#two_step_login .link{
margin: 0 auto 17px;
text-align: center;
}
/* #two_step_login .link a{
font-size: 16px;
font-weight: 500;
color: #168AC7;
text-decoration: underline;
}
*/
/*----------
2段階認証（電話番号）
----------*/
#two_step_tel .tel_country{
max-width: 100px;
}
/*----------
2段階認証（コード入力）
----------*/
#two_step_code .text_box_ptn02 .form{
margin: 0 auto 50px;
}
#two_step_code .text_box_ptn02 .back{
text-align: center;
}
#two_step_code .text_box_ptn02 .back{
margin: 0 auto 30px;
}
/*----------
8_入力内容確認
----------*/
#signup_confirm{
margin-top: 82px;
}

#signup_confirm .form{
margin: 0 auto 30px;
}
#signup_confirm .page_title_ptn01.white h1 span{
display: inline-block;
max-width: 136.44px;
}
#signup_confirm .btn_back{
margin: 0 auto;
max-width: 200px;
}
#signup_confirm.signup_bg::before,
#signup_confirm.signup_bg::after{
height: 60%;
}
/*----------
アカウント情報 - セキュリティ
----------*/
#security .text{
margin-bottom: 15px;
font-weight: 600;
}
#security.setting_page .btn_edit a::after{
content: none;
}
#security .btn_ptn03{
margin: 40px 0 0 0;
max-width: 240px;
}
#login_history_on{
cursor: pointer;
}
#security .delete a{
display: flex;
align-items: center;
text-decoration: underline;
font-size: 18px;
line-height: 28px;
font-weight: 700;
color: #D62B2B;
cursor: pointer;
}
#security .delete a::before{
content: '';
display: inline-block;
margin: 0 15px 0 0;
width: 16.8px;
height: 20.5px;
background-image: url(../img/common/delete.png);
background-repeat: no-repeat;
background-size: contain;
}
#security .flex_box{
display: flex;
align-items: center;
margin: 40px 0 0;
}
#security .flex_box .btn_ptn03{
margin: 0;
}
#security .flex_box .btn_edit{
margin: 0 0 0 28px;
}
#security .flex_box .btn_edit a{
display: flex;
align-items: center;
text-decoration: none;
font-size: 16px;
}
#security .flex_box .btn_edit a::before{
content: '';
display: block;
margin: 0 8px 0 0;
width: 16px;
height: 16px;
background-image: url(../img/common/gia_blue.png);
background-repeat: no-repeat;
background-size: contain;
}
#security .flex_box .btn_edit:last-of-type a::before{
background-image: url(../img/common/delete_blue.png);
}
#security .gray{
font-size: 14px;
font-weight: 400;
line-height: 24px;
}

#security .cnt_box04::after{
content: '';
position: fixed;
width: 64px;
height: 117px;
background-image: url(../img/setting/illust02.png);
background-size: contain;
background-repeat: no-repeat;
right: 6%;
bottom: 20%;
}
#security #account_delete .mail_change{
position: absolute;
margin: 0;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}
#security #account_delete_done .contents_in{
position: static;
}
#security #account_delete_done .form_box_ptn02{
max-width: 850px;
}
#security #account_delete_done .title{
padding: 0;
font-size: 40px;
font-weight: 700;
letter-spacing: 0.05em;
line-height: 58px;
margin-bottom: 20px;
color: #168AC7;
text-align: center;
}
#security #account_delete_done .btn_ptn03{
margin: 0 auto;
}
/*----------
ログイン後アカウント画面 - 通知
----------*/

#notification .main_contents {
  height: 100vh;
}
#notification .checkbox{
margin: 0 0 20px 30px;
}
#notification .checkbox.parent{
margin-left: 0;
}
#notification .text{
margin: 0 0 20px;
font-size: 14px;
}
#notification .form_box{
margin: 0 0 40px;
}
#notification .select_box{
display: flex;
align-items: center;
}
#notification .form_box .box01{
display: flex;
align-items: center;
flex-wrap: wrap;
margin: 0 0 14px;
}
#notification .form_box .box01 .checkbox{
margin: 0 25px 0 0;
}
#notification .form_box .select{
margin: 10px 0;
padding: 0 0 0 10px;
height: 40px;
width: 100px;
}
#notification .checkbox_ptn02 label{
margin: 0 3px 0 0;
}
#notification .checkbox_ptn02 label span{
padding: 10px 15px;
font-size: 14px;
}
#notification .form_box .parent.unchecked ~ .checkbox {
  color: #999;
}

#notification .form_box .parent.unchecked ~ .checkbox label::before {
background-color: #f2f2f2;
border-color: #999;
}
#notification .form_box .parent.unchecked ~ .checkbox label::after{
border-bottom: 3px solid #4d4d4d;
border-right: 3px solid #4d4d4d;
}
#notification .select_box .and{
margin: 0 10px;
font-size: 14px;
}
#notification .btn_submit{
margin: 0;
}
#notification .cnt_img {
width: 7.5vw;
position: fixed;
bottom: 169.43px;
right: 6vw;
max-width: 60px;
}
#notification .checkbox input[type=checkbox]:checked + label::before{
background-color: #168AC7;
}
#notification .checkbox input[type=checkbox]:checked ~ label::after{
border-bottom: 3px solid #fff;
border-right: 3px solid #fff;
}
/*----------
ログイン履歴
----------*/
#login_history{
display: none;
}
#login_history .page_contents{
margin: 0;
}
#login_history .contents_in{
max-width: 1000px;
}
#login_history .cnt_top{
display: flex;
justify-content: space-between;
margin: 0 auto 46px;
}
#login_history .link{
margin: 0 0 26px;
}
#login_history .link .blue{
font-size: 14px;
line-height: 20px;
text-decoration: underline;
}
#login_history .cnt_top .cnt_img{
width: 7.6%;
}
#login_history .text_box_ptn01 .table_box{
height: 450px;
overflow-y: scroll;
}

#login_history .text_box_ptn01 .table_box::-webkit-scrollbar {
width: 10px;
height: 10px;
}

/*スクロールバーの軌道*/
#login_history .text_box_ptn01 .table_box::-webkit-scrollbar-track {
border-radius: 10px;
background: #D1D1D2;
}

/*スクロールバーの動く部分*/
#login_history .text_box_ptn01 .table_box::-webkit-scrollbar-thumb {
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
border-radius: 10px;
}
#login_history .text_box_ptn01{
margin-bottom: 116px;
padding: 26px 5px 45px;
max-width: 1000px;
}
#login_history table{
margin: 0 auto 82px;
width: 100%;
}
#login_history tr{
border-bottom: 1px solid #EDEDED;
}
#login_history th{
position: sticky;
top: -1px;
background: #fff;
padding: 0 0 27px;
box-sizing: border-box;
font-size: 18px;
font-weight: 500;
line-height: 26px;
color: #147EBE;
text-align: left;
}
#login_history td{
padding: 20px 0 20px;
box-sizing: border-box;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #707070;
}

#login_history tr th:nth-of-type(1){
padding-left: 4.2%;
}
#login_history tr td:nth-of-type(1){
padding-left: 4.2%;
width: 22.3%;
}
#login_history tr th:nth-of-type(2),
#login_history tr td:nth-of-type(2){
padding-left: 2.8%;
width: 35.6%;
}
#login_history tr th:nth-of-type(3),
#login_history tr td:nth-of-type(3){
padding-left: 4%;
width: 18.4%;
}
#login_history tr th:nth-of-type(4),
#login_history tr td:nth-of-type(4){
padding-left: 3%;
width: 23.7%;
}
#login_history td:nth-of-type(even){
font-weight: 700;
}
#login_history td.computer::before,
#login_history td.portable::before{
content: '';
display: inline-block;
margin: 0 10px 0 0;
vertical-align: middle;
width: 28px;
height: 23px;
background-image: url(../img/setting/login_history/pc_icon.png);
background-size: contain;
}
#login_history td.portable::before{
background-image: url(../img/setting/login_history/sp_icon.png);
}
#login_history .kome_text{
padding: 8px 0 0 4.2%;
font-size: 14px;
line-height: 20px;
font-weight: 500;
color: #0B0B0B;
}
/*----------
新規会員登録 - アカウント登録
----------*/
#new_account{
margin-top: 82px;
}
#new_account .login_top_box{
margin: 0 auto 45px;
}
#new_account .btn_ptn03{
margin: 0 auto 15px;
max-width: 353px;
width: 100%;
}
#new_account .page_title_ptn01.white h1 span{
max-width: 136.44px;
display: inline-block;
}
/*----------
新規会員登録 - アカウント登録（メールアドレスで登録）
----------*/
#mail_temporary_registration{
margin-top: 82px;
}
#mail_temporary_registration .kome{
margin: 0 0 5px;
font-size: 12px;
line-height: 17px;
font-weight: 500;
}
#mail_temporary_registration .page_title_ptn01.white h1 span{
display: inline-block;
max-width: 136.44px;
}
#mail_temporary_registration .pas{
margin: 0 0 0 0;
}
#mail_temporary_registration .privacy_box{
margin: 0 0 32px;
}
#mail_temporary_registration .recaptcha{
display: flex;
justify-content: center;
margin: 0 0 38px;
}
#mail_temporary_registration.signup_bg::before{
height: 77%;
}
#mail_temporary_registration.signup_bg::after{
height: 77%;
}
/*----------
新規会員登録 – メール送信(認証番号)
----------*/
#mail_send{
margin-top: 82px;
}
#mail_send .form_box_ptn02{
margin: 0 auto;
}
#mail_send .title{
margin: 0 auto 20px;
text-align: center;
}
#mail_send .lead{
margin: 0 auto 30px;
line-height: 24px;
text-align: center;
}
#mail_send .cnt_text{
margin: 0 auto 30px;
text-align: center;
}
#mail_send .btn_ptn03{
margin: 0 auto;
width: 100%;
max-width: 300px;
}
#mail_send .form_box_ptn02 dd{
padding: 0;
}
#mail_send .form_box_ptn02 .btn_submit{
margin: 0 auto 30px;
max-width: 234px;
/* background: #fff; */
}
/* #mail_send .form_box_ptn02 .btn_submit input[type="submit"]{
color: #333;
}
#mail_send .form_box_ptn02 .btn_submit input[type="submit"]:hover{
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
color: #fff;
} */
#mail_send .form_g{
margin: 0 auto;
max-width: 353px;
}
#mail_send .link{
margin: 17px;
text-align: center;
text-decoration: underline;
}
#mail_send .mail{
margin: 0 auto 45px;
text-align: center;
}
/*----------
新規会員登録 - アカウント登録（SNSで登録）
----------*/
#sns_temporary_registration{
margin-top: 82px;
}

#sns_temporary_registration .kome{
margin: 0 0 5px;
font-size: 12px;
line-height: 17px;
font-weight: 500;
}
#sns_temporary_registration .pas{
margin: 0 0 0 0;
}
#sns_temporary_registration .privacy_box{
margin: 0 0 32px;
}
#sns_temporary_registration .page_title_ptn01.white h1 span{
display: inline-block;
max-width: 136.44px;
}
#sns_temporary_registration .recaptcha{
display: flex;
justify-content: center;
margin: 0 0 38px;
}
#sns_temporary_registration .mail{
border: 1px solid #B2B2B2;
border-radius: 3px;
padding: 11px 0 13px 16px;
background-color: #f7f7f7;
}

#sns_temporary_registration.signup_bg::before,
#sns_temporary_registration.signup_bg::after{
height: 77%;
}

/*----------
ログイン画面 – パスワード不明
----------*/
#password_forget{
margin-top: 82px;
}

#password_forget .form{
margin: 0 auto 59px;
}
#password_forget .form_g{
margin: 0 auto;
max-width: 353px;
}
#password_forget .cnt_text{
margin: 0 auto 30px;
text-align: center;
}

#password_forget .form_box_ptn02 dd{
padding: 0;
}
#password_forget .recaptcha{
display: flex;
justify-content: center;
margin: 0 auto 30px;
}
#password_forget .link{
margin: 0 auto 14px;
text-align: center;
}
#password_forget .link a{
text-align: center;
font-size: 15px;
text-decoration: underline;
}
#password_forget.signup_bg::before,
#password_forget.signup_bg::after{
height: 75%;
}
/*----------
ログイン画面 – パスワード再設定
----------*/
#password_reset{
margin-top: 82px;
}



#password_reset .form_g{
margin: 0 auto 40px;
}

/* #password_reset .btn_passview{
margin: 16px 15px 16px 0;
width: 23px;
height: 15px;
background-image: url(../img/common/pas_icon.png);
background-size: contain;
background-repeat: no-repeat;
} */
#password_reset .input_pass{
margin: 0;
border: none;
padding: 11px 0 13px 16px;
background: rgba(255,255,255,0);
outline: none;
width: calc(100% - 18px);
}
#password_reset .link{
margin: 50px auto 0;
text-align: center;
}
#password_reset .link a{
text-decoration: underline;
}
#password_reset .lead{
margin: 0 0 30px;
line-height: 28px;
text-align: center;
font-size: 14px;
}
#password_reset .mail{
margin: 0 auto 50px;
text-align: center;
}

/*----------
ログイン画面 – パスワード再設定完了
----------*/

#password_done{
margin-top: 82px;
}
#password_done .cnt_text{
margin: 0 auto 60px;
text-align: center;
}
#password_done .btn_ptn03{
margin: 0 auto;
max-width: 318px;
}

/*----------
ログイン画面 – 2段階認証
----------*/

#two_step_login .lead{
margin: 0 0 30px;
line-height: 28px;
text-align: center;
font-size: 14px;
}

/*----------
アカウントの削除
----------*/
#account_delete.mail_change .lead{
margin: 0 0 40px;
}
#account_delete.mail_change .form_box_ptn02 .pas{
margin: 15px 0 5px;
}

/*----------
アカウント削除完了
----------*/

#account_delete_done.mail_change_ptn02 .form_box_ptn02{
border: none;
box-shadow: none;
background-color: rgba(255, 255, 255, 0);
}
#account_delete_done .form_box_ptn02 .btn_ptn02{
margin: 0 auto;
max-width: 266px;
}

/*----------
途中離脱
----------*/
.page_exit .mail_change{
position: absolute;
margin: 0;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}
.page_exit .contents_in{
position: static;
}
.page_exit .form_box_ptn03{
max-width: 850px;
margin: 0 auto 30px;
display: block;
background: #fff;
border: 1px solid #D1D1D1;
border-radius: 20px;
padding: 14px;
box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
}
.page_exit .text_box_ptn01 .title{
padding: 0;
font-size: 32px;
font-weight: 700;
letter-spacing: 0.05em;
line-height: 1.4;
margin-bottom: 16px;
color: #168AC7;
text-align: center;
}
.page_exit .btn_g {
display: flex;
justify-content: center;
}
.page_exit .btn_ptn02{
margin: 0 60px 0 0;
}
.page_exit .btn_submit {
margin: 0;
width: auto;
}

.page_exit .btn_submit button,
.page_exit .btn_submit input[type="submit"],
.page_exit .btn_submit input[type="button"] {
  display:block;
  padding: 12px 28px;
}

.page_exit .link {
  margin: auto;
  text-align: center;
  margin: 20px auto 14px;
}

.page_exit .link .link_ptn01 {
  vertical-align: bottom;
  color: #168AC7;
  text-decoration: underline;
  cursor: pointer;
}

.page_exit .link .link_ptn01:hover {
  background: #168AC7;
  color: #fff;
  text-decoration: none;
}


/*----------
全エラー画面共通
----------*/
#error404{
margin: 82px 0 0;
padding: 60px 0 80px;
}
#error404 .page_title_ptn01{
margin: 0 auto 60px;
}
#error404 .cnt_img{
margin: 0 auto 60px;
max-width: 251px;
}
#error404 p{
margin: 0 auto 40px;
letter-spacing: 0.03em;
text-align: center;
}
#error404 .btn_box{
display: flex;
justify-content: center;
margin: 0 auto;
}
#error404 .btn{
max-width: 220px;
}
#error404 .btn:first-of-type{
margin: 0 20px 0 0;
}
#error404 .error_code{
text-align: center;
font-size: 20px;
margin-bottom: 15px;
}
/*----------
FAQ
----------*/
#faq .table_contents{
margin: 0 0 50px;
}

#faq .table_contents li{
margin: 0 0 15px;
}

#faq .table_contents li a{
color: #168AC7;
}
#faq .table_contents li a::before{
content:'・';
display: inline-block;
}
/*----------
会社概要
----------*/
#company h1{
margin: 0 0 50px;
}
#company dl{
display: flex;
flex-wrap: wrap;
}
#company dt{
margin: 0 0 40px;
padding: 0 0 20px 10px;
border-bottom: 1px solid #D1D1D1;
width: 25%;
}
#company dd{
margin: 0 0 40px;
padding: 0 0 20px;
border-bottom: 1px solid #D1D1D1;
width: 75%;
}
#company dt:last-of-type,
#company dd:last-of-type{
border: none;
}
#company dd p{
margin: 0 0 20px;
}
#company ol p{
text-indent: -1em;
padding-left: 1em;
}
/*----------
メールアドレス変更（確認完了）
----------*/
#mail_change .pas,
#password_change .pas{
margin-bottom: 0;
}
/*----------
メールアドレス変更（確認完了）
----------*/
#mail_change_done{
margin-top: 82px;
}
#mail_change_done.signup_bg::after{
content: none;
}
#mail_change_done h1{
text-align: center;
margin-bottom: 20px;
}
#security #account_delete_done .btn_ptn03.btn_top_link a{
background: #fff;
color: #168AC7;
}

#security #account_delete_done .btn_ptn03.btn_top_link a:hover {
color: #fff;
background: transparent linear-gradient(
180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
#code  .form_box_ptn02 .btn_submit{
margin-bottom: 50px;
}
#code .link{
margin-bottom: 25px;
text-align: center;
}
#code .link a{
text-decoration: underline;
}
