/* ドリルダウンアニメーション */

@keyframes slideIn {
    from {
        transform: translateX(400px);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(-400px);
    }
}

@keyframes slideBack {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(400px);
    }
}

@keyframes slideReturn {
    from {
        transform: translateX(-400px);
    }
    to {
        transform: translateX(0px);
    }
}
.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.slideIn {
    /* 使用keyframes：moveLeft */
    animation-name: slideIn;
    /* 0から100にいくまでの時間は1秒 */
    animation-duration: 0.1s;
    /* 0から100までどんな感じに動かす */
    animation-timing-function: ease-out;
    /* アニメーションは繰り返す */
    animation-iteration-count: none;
    /* アニメーションが始まる前と終わった後の状態 */
    animation-fill-mode: forwards;
}

.slideOut {
    animation-name: slideOut;
    animation-duration: 0.1s;
    animation-timing-function: ease-out;
    animation-iteration-count: none;
    animation-fill-mode: forwards;
}

.slideBack {
    animation-name: slideBack;
    animation-duration: 0.1s;
    animation-timing-function: ease-out;
    animation-iteration-count: none;
    animation-fill-mode: forwards;
}

.slideReturn {
    animation-name: slideReturn;
    animation-duration: 0.1s;
    animation-timing-function: ease-out;
    animation-iteration-count: none;
    animation-fill-mode: forwards;
}


/* デザイン */
/*
.hide-box {
    overflow: hidden;
}

.slide-scroll-bar {
    overflow: auto;
    height: 150px;
}

.parent-div {
    width: auto;
}

.select-area {
    padding: 10px 10px;
    max-width: 394px;
    width: 100%;
    margin: 0 auto 10px;
    display: block;
    position: relative;
    background: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.button_area {
    width: 100%;
    background-color: #fbeefc;
    border-left: 8px solid #c92ad2;
    color: #c92ad2;
    padding: 2em;
}

@media only screen and (max-width:750px) {
    .select-area {
        max-width: 434px;
    }
}
*/
.cursor {
    cursor: default;
    cursor: hand;
}
.display-none {
    display: none;
}
.display-block {
    display: block;
}
.button_area {
    cursor: pointer;
    padding: 13px 16px;
}
/*
#signup .form {
    overflow: hidden;
}
*/