@charset "UTF-8";
/* CSS Document */
/*--------------------
01.共通
クッキー
01_01.ボタン・本文
01_02.フォーム
01_03.フォーム入力内容確認
01_04.背景色
02.ヘッダー
03.フッター
04.ログイン後 サイドメニュー
--------------------*/

/*----------
01.共通
----------*/
html{
position: relative;
min-height: 100%;
}

body{
font-family: 'Noto Sans JP';
color: #4d4d4d;
-webkit-font-smoothing: antialiased;

min-height: 100%;
padding-bottom: 137px;
}

body.fixed {
position: fixed;
width: 100%;
height: 100%;
left: 0;
}

.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}

*{
box-sizing: border-box;
}

*:focus {
outline: none;
}



#wrapper{
width: 100%;
overflow: hidden;
font-family: 'Noto Sans JP', sans-serif;
font-size: 15px;
color: #333333;
font-weight: 400;
}

img{
display: block;
width: 100%;
height: auto;
}

.pc{
display: block;
}
.sp{
display: none;
}


.contents_in{
margin: 0 auto;
max-width: 1080px;
width: 98%;
}

.center_contents{
display: flex;
flex-direction: column;
align-items: center;
}

.page_contents{
margin-top: 80px;
}
.gray{
color: #707070;
}
.red{
color: #D62B2B;
}
.blue{
color: #168AC7;
}
/*----------
クッキー
----------*/
#cookie{
position: fixed;
padding: 5px;
bottom: 0;
left: 0;
width: 100%;
background-color: #4A4A4A;
z-index: 50;
}
#cookie .cnt_text{
margin-bottom: 15px;
}
#cookie .cnt_wrap{
padding: 25px;
background-color: #fff;
}
#cookie .btn_box{
display: flex;
justify-content: center;
}
#cookie .btn_submit{
width: 220px;
margin: 0 20px;
}
#cookie .btn_submit:first-of-type{
  background: linear-gradient(0deg, rgba(175,6,6,1) 0%, rgba(214,43,43,1) 100%);
/*background: linear-gradient(0deg, rgba(175,6,6,1) 0%, rgba(214,43,43,1) 100%);*/
  width: 220px;
  height: 56px;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-family: 'Noto Sans JP', sans-serif;
  cursor: pointer;
}
#cookie .btn_submit:nth-of-type(2){
  background: transparent linear-gradient(
180deg
, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
  width: 220px;
  height: 56px;
  color: #fff;
  text-align: center;
  padding: 16px 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-family: 'Noto Sans JP', sans-serif;
  cursor: pointer;
}
/*--------------------
01_01.ボタン・本文
--------------------*/

.btn_ptn01{
/*  display: flex;*/
/*  position: relative;*/
border-radius: 10px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
max-width: 340px;
width: 100%;
background: #fff;
}
.btn_ptn01:hover{
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
.btn_ptn01 a::before{
content: '';
display: block;
position: absolute;
background-image: url(../img/common/arrow01.png);
background-size: cover;
background-repeat: no-repeat;
width: 6px;
height: 12px;
top: 50%;
right: 30px;
transform: translateY(-50%);
z-index: 1;
}
.btn_ptn01 a::after{
content: '';
display: block;
position: absolute;
top: 0;
right: 0;
border-radius: 0 10px 10px 0;
width: 65px;
height: 100%;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
/* background: transparent linear-gradient(137deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box; */
}
.btn_ptn01 a:hover::after{
background: none;
}
.btn_ptn01 a{
display: block;
position: relative;
border-radius: 10px 0 0 10px;
padding: 29px 70px 29px 0;
font-size: 20px;
font-weight: 500;
letter-spacing: 0.05em;
color: #168AC7;
text-align: center;
width: 100%;
}
.btn_ptn01:hover a{
color: #fff;
}
.btn_ptn02{
width: 100%;
max-width: 136px;
}
.btn_ptn02 a{
display: block;
position: relative;
border-radius: 8px;
padding: 16px 0;
font-size: 16px;
font-weight: 500;
letter-spacing: 0.05em;
color: #168AC7;
text-align: center;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
width: 100%;
background-color: #fff;
}
.btn_ptn02 a:hover{
color: #168AC7;
/* background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box; */
background: #E2F5FF;
}
.btn_ptn03{
width: 100%;
max-width: 136px;
}
.btn_ptn03 a{
display: block;
border-radius: 8px;
padding: 16px 0;
font-size: 16px;
font-weight: 500;
letter-spacing: 0.05em;
color: #fff;
width: 100%;
text-align: center;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
.btn_ptn03 a:hover{
/* color: #168AC7;
background: #fff; */
background: #168AC7;
}

.btn_ptn03.sns{
margin: 0 auto 15px;
max-width: 353px;
width: 100%;
}
.btn_ptn03.sns a{
position: relative;
}
.btn_ptn03.google a,
.btn_ptn03.twitter a{
position: relative;
}
.btn_ptn03 a::before{
content: '';
display: inline-block;
position: absolute;
vertical-align: middle;
margin-right: 8px;
}
.btn_ptn03.google a{
padding-left: 26px;
}
.btn_ptn03.google a::before{
background: url("../img/common/icon01_google.png") no-repeat center/contain;
width: 14px;
height: 14px;
top: 50%;
left: 12.75%;
transform: translateY(-50%);
z-index: 2;
}
.btn_ptn03.google a::after{
content: '';
display: block;
position: absolute;
border: 1px solid #4285F4;
border-radius: 5px;
height: 30px;
width: 30px;
background-color: #fff;
top: 50%;
left: 10.2%;
transform: translateY(-50%);
}
.btn_ptn03.twitter a{
padding-left: 26px;
}
.btn_ptn03.twitter a::before{
width: 21px;
height: 17px;
background: url("../img/common/icon01_twitter.png") no-repeat center/contain;
top: 50%;
left: 11.75%;
transform: translateY(-50%);
}
/* .btn_ptn03.twitter:hover a::before{
width: 21px;
height: 17px;
background: url("../img/common/icon02_twitter.png") no-repeat center/contain;
} */

.headline_l{
font-size: 70px;
font-weight: 700;
}
.headline_s{
font-size: 50px;
font-weight: 700;
}
.lead{
font-size: 16px;
font-weight: 500;
}
.title{
font-size: 40px;
font-weight: 700;
}
.sub_title{
font-size: 26px;
font-weight: 700;
}

p{
font-size: 15px;
font-weight: 400;
line-height: 28px;
}
.body{
font-size: 16px;
font-weight: 400;
line-height: 28px;
}
a.link_ptn01{
color: #168AC7;
text-decoration: underline;
text-align: right;
font-size: 12px;
}
a.link_ptn01:hover{
color: #fff!important;
background: #168AC7;
text-decoration: none;
}
a.link_ptn01:visited{
color: #647ED8;
}

.page_title_ptn01{
  margin-bottom: 30px;
  text-align: center;
}

.page_title_ptn01 h1{
font-size: 40px;
font-weight: 700;
letter-spacing: 0.05em;
line-height: 58px;
margin-bottom: 20px;
color: #168AC7;
text-align: center;
}
.page_title_ptn01 p{
text-align: center;
font-weight: 500;
}

.page_title_ptn01.white{
color: #fff;
}
.page_title_ptn01.white h1{
color: #fff;
}


.text_box_ptn01{
max-width: 476px;
margin: 0 auto 30px;
display: block;
background: #fff;
border: 1px solid #D1D1D1;
border-radius: 20px;
padding: 40px;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.text_box_ptn01 h2{
margin: 0 auto 30px;
font-size: 18px;
line-height: 27px;
text-align: center;
font-weight: 600;
}

.login_top_box h2{
margin: 0 0 20px;
font-size: 18px;
font-weight: 700;
text-align: center;
}
.login_top_box .and{
margin: 10px 0 0;
border-top: 1px solid #D1D1D1;
padding: 25px 0 0 0;
text-align: center;
font-size: 18px;
font-weight: 700;
}
.sns_text{
display: none;
margin-bottom: 30px;
text-align: center;
font-size: 18px;
}
.sns_text.this{
display: block;
}
.sns_text::before{
content: '';
display: inline-block;
margin-right: 10px;
width: 18px;
height: 18px;
background-size: contain;
background-repeat: no-repeat;
background-position: center;
vertical-align: middle;
}
.sns_text.google::before{
background-image: url(../img/common/g_logo.png);
}
.sns_text.twitter::before{
  background-image: url(../img/common/t_logo.png);
}
/*--------------------
01_02.フォーム
--------------------*/
/* form */
.form_title{
font-size: 15px;
line-height: 20px;
margin-bottom: 14px;
font-weight: bold;
}
.form_text{
font-size: 15px;
line-height: 20px;
margin-bottom: 14px;
}

.form_text .required{
display: inline-block;
color: #fff;
background: #D11313;
border-radius: 3px;
font-size: 12px;
line-height: 18px;
padding: 0 7px 1px;
letter-spacing: 0.1em;
vertical-align: middle;
margin: -2px 0 0 15px;
}


.form_text .optional{
display: inline-block;
color: #fff;
background: #888888;
border-radius: 3px;
font-size: 12px;
line-height: 18px;
padding: 0 7px 1px;
letter-spacing: 0.1em;
vertical-align: middle;
margin: -2px 0 0 15px;
}

.form_g dd{
margin-bottom: 30px;
font-size: 15px;
}
.form_g .country{
position: relative;
}
.form_g .country .cursor{
margin: 0 auto;
border: 1px solid #CECECE;
padding: 13px 16px;
font-size: 16px;
width: 100%;
max-width: 404px;
border-radius: 4px;
display: block;
font-family: 'Noto Sans JP', sans-serif;
background: url(../img/common/arrow01_bottom.png) no-repeat right 12px center, linear-gradient(0deg, rgba(239,239,239,1) 0%, rgba(255,255,255,1) 100%);
background-size: 12px auto;
color: #4d4d4d;
height: 48px;
cursor: pointer;
}
/* 追加 ここから */
.form .form_g .select {
  cursor: pointer;
}
/* 追加 ここまで */
.form_g .country .child_box{
position: absolute;
border: 1px solid #CECECE;
border-top: none;
width: 100%;
height: 230px;
overflow-y: scroll;
bottom: -230px;
left: 0;
z-index: 1;
-ms-overflow-style: none;
scrollbar-width: none;
}
.form_g .country .child_box::-webkit-scrollbar{
display:none;
}
.form_g .country .select{
border-bottom: 1px solid #CECECE;
/*padding: 13px 16px;*/
font-size: 16px;
width: 100%;
max-width: 404px;
display: block;
font-family: 'Noto Sans JP', sans-serif;
background: #fff;
background-size: 12px auto;
color: #4d4d4d;
height: 48px;
}
.form_g .country .select:hover{
background-color: #168AC7;
}
.form_g .country .select.back{
background-color: #E8E8E8;
}
.form_g .country .select.back:hover{
background-color: #CCC;
}
.form_g .country .select button{
color: #4d4d4d;
font-size: 16px;
}
.form_g .country .select:hover button{
color: #fff;
font-family: 'Noto Sans JP', sans-serif;
}
.form_g .country .select.back button{
color: #168AC7;
}
.form_g .country .select.back:hover button{
color: #168AC7;
}
.form_g .country .select.back button::before{
content: '';
display: inline-block;
margin: 0 10px 0 0;
height: 12px;
width: 6px;
background-image: url(../img/common/arrow01_b.png);
background-repeat: no-repeat;
background-size: contain;
}
.form_g .country .select.back:hover button{
transform: translateY(-2px);
}
.form_g .country .select:hover button.back::before{
background-image: url(../img/common/arrow01_w.png);
}
.form_g .country .select:last-of-type{
border: none;
}

.form_g .country .blue_box .select button{
  display: flex;
  align-items: center;
  width: 100%;
}
.form_g .country .blue_box .select.item button{
justify-content: space-between;
}
.form_g .country .blue_box .select.item button::after{
content: '';
display: block;
height: 12px;
width: 6px;
background-image: url(../img/common/arrow_br.png);
background-repeat: no-repeat;
background-size: contain;
}
.form_g .country .blue_box .select.item:hover{
background-color: #81D1FC;
}
.form_g .country .blue_box .select.back:hover button::after{
background-image: url(../img/common/arrow_wr.png);
}
.form_g .country .blue_box .select:hover button{
transform: translateY(-2px);
}

.form_attention_text{
font-size: 13px;
line-height: 19px;
}

.form_error_text{
font-size: 13px;
line-height: 19px;
font-weight: 700;
color: #D62B2B;
margin-bottom: 40px;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"] {
padding: 13px 14px 13px;
border-radius: 3px;
outline: none;
background:#F7F7F7;
border: 1px solid #D1D1D1;
width: 100%;
display: block;
margin: 0 0 5px;
font-weight: 400;
font-family: 'Noto Sans JP', sans-serif;
font-size:16px;
height: 48px;
}

textarea {
padding: 13px 14px 13px;
border-radius: 3px;
outline: none;
background:#F7F7F7;
border: 1px solid #D1D1D1;
width: 100%;
display: block;
margin: 0 0 5px;
font-weight: 400;
font-family: 'Noto Sans JP', sans-serif;
font-size:16px;
height: 250px;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
textarea:focus{
background: #fff;
border: 1px solid #168AC7;
}

input[type="text"].error,
input[type="tel"].error,
input[type="password"].error,
input[type="email"].error,
textarea.error{
border: 1px solid #E59A9A;
background: #FFF2F2;
}

::placeholder{
color:#ccc;
}

input.year{
display: inline-block;
max-width: 70px;
margin-right: 8px;
}

input.month,
input.day{
display: inline-block;
max-width: 50px;
margin: 0 10px;
}

.checkbox{
display: block;
width: auto;
font-size: 16px;
margin-bottom: 10px;
}
.checkbox input[type=checkbox]{
opacity: 0;
margin: 0;
-webkit-appearance: auto;
height: 1px;
display: none;
/*visibility: hidden;*/
}
.checkbox label{
padding: 0 0 5px 28px;
display: inline-block;
position: relative;
}
.checkbox label a{
text-decoration: underline;
}
.checkbox label::before{
content: '';
display: block;
width: 18px;
height: 18px;
border: 2px solid #168AC7;
border-radius: 2px;
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 0;
box-sizing: border-box;
}
.checkbox label::after{
content: '';
display: none;
width: 5px;
height: 9px;
border-bottom: 3px solid #6C6C6C;
border-right: 3px solid #6C6C6C;
position: absolute;
top: 40%;
transform: translateY(-50%) rotate(45deg);
left: 5px;
}
.checkbox input[type=checkbox]:checked ~ label::after{
display: block;
}
.checkbox input[type=checkbox].error ~ label::before{
background: #FFF2F2;
border: 2px solid #E59A9A;
}

.checkbox_ptn02{
display: flex;
flex-wrap: wrap;
}

.checkbox_ptn02 label {
margin: 0 8px 10px 0;
}
.checkbox_ptn02 label input {
display: none;
}
.checkbox_ptn02 label span {
display: block;
border: 1px solid #1689C7;
border-radius: 3px;
padding: 8px 9px;
font-size: 13px;
color: #1689C7;
text-align: center;
width: 100%;
background-color: #fff;
cursor: pointer;
transition: .2s all;
}

.checkbox_ptn02 label input:checked + span,
.checkbox_ptn02 label input + span:hover{
color: #FFF;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
.checkbox_ptn02 label input:checked + .new,
.checkbox_ptn02 label input + .new:hover{
background: transparent linear-gradient(180deg, rgba(129, 209, 252, 1) 0%, rgba(100, 161, 221, 1) 100%) 0% 0% no-repeat padding-box;
}
.checkbox_ptn02 label input:disabled + span{
color: #C1C3C4;
background-color: #F7F7F7;
border: 1px solid #C1C3C4;
cursor:none;
pointer-events: none;
}



input[type="radio"] {
display: none;
}
input[type="radio"]:checked + label {
background: none;
}
.radio{
display: inline-block;
width: auto;
margin-right: 20px;
font-size: 15px;
}
.radio + .form_error_text{
line-height: 2.3;
}
.radio input[type=radio]{
opacity: 0;
height: 1px;
margin: 0;
-webkit-appearance: auto;
/*visibility: hidden;*/
}
.radio label{
padding-left: 28px;
display: block;
position: relative
}
.radio label::before{
content: '';
display: block;
width: 20px;
height: 20px;
border: 2px solid #d1d1d1;
border-radius: 50%;
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 0;
}
.radio label::after{
content: '';
display: none;
width: 14px;
height: 14px;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
border-radius: 50%;
position: absolute;
top: 50%;
transform: translateY(-50%);
left: 5px;
}
.radio input[type=radio]:checked ~ label::after{
display: block;
}

.radio input[type=radio]:disabled ~ label{
color: #C1C3C4;
}

select {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
outline: none;
border: 1px solid #CECECE;
padding: 13px 16px;
font-size: 16px;
width: 100%;
max-width: 404px;
border-radius: 4px;
display: block;
font-family: 'Noto Sans JP', sans-serif;
background: url("../img/common/arrow01_bottom.png") no-repeat right 12px center, rgb(239,239,239);
background: url("../img/common/arrow01_bottom.png") no-repeat right 12px center, linear-gradient(0deg, rgba(239,239,239,1) 0%, rgba(255,255,255,1) 100%);
background-size: 12px auto;
color: #4d4d4d;
height: 48px;
}
select:disabled {
opacity: 0.4;
}

select:nth-of-type(n+2){
margin-top: 15px;
}

button,
input[type="submit"],
input[type="button"]{
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
padding: 0;
border: none;
outline: none;
background: transparent;
}

.btn_submit{
display: block;
width: 100%;
max-width: 353px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
border-radius: 8px;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
margin: 0 auto;
}
.btn_submit.blue{
background: rgb(100,161,221);
background: linear-gradient(0deg, rgba(100,161,221,1) 0%, rgba(129,209,252,1) 100%);
}
.btn_submit.red{
background: rgb(175,6,6);
background: linear-gradient(0deg, rgba(175,6,6,1) 0%, rgba(214,43,43,1) 100%);
max-width: 240px;
}

.btn_submit button,
.btn_submit input[type="submit"],
.btn_submit input[type="button"] {
width: 100%;
display: block;
color: #fff;
text-align: center;
padding: 16px 0;
border-radius: 8px;
font-size: 16px;
font-weight: 500;
letter-spacing: 0.05em;
font-family: 'Noto Sans JP', sans-serif;
cursor: pointer;
transition: .2s all;
}

.btn_submit button:hover,
.btn_submit input[type="submit"]:hover,
.btn_submit input[type="button"]:hover {
background-color: #168AC7;
}
.btn_submit.blue button:hover,
.btn_submit.blue input[type="submit"]:hover,
.btn_submit.blue input[type="button"]:hover{
background-color: #168AC7;
}
.btn_submit.red button:hover,
.btn_submit.red input[type="submit"]:hover,
.btn_submit.red input[type="button"]:hover{
color: #D62B2B;
background: #FFE2E2;
}

.btn_submit button:disabled,
.btn_submit input[type="submit"]:disabled,
.btn_back input[type="submit"]:disabled{
background: #F7F7F7;
border: 1px solid #C7C9CB;
color: #CFCFCF;
pointer-events: none;
}
.btn_submit button:disabled:hover,
.btn_submit input[type="submit"]:disabled:hover,
.btn_back input[type="submit"]:disabled:hover{
background: #F7F7F7;
border: 1px solid #C7C9CB;
color: #CFCFCF;
}

.btn_back{
width: 100%;
max-width: 186px;
border-radius: 8px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
margin: 0 auto;
}

.btn_back input[type="submit"]{
width: 100%;
display: block;
color: #168AC7;
text-align: center;
padding: 14px 0;
border-radius: 8px;
font-size: 16px;
font-weight: 500;
letter-spacing: 0.05em;
font-family: 'Noto Sans JP', sans-serif;
cursor: pointer;
transition: .2s all;
}

.btn_back input[type="submit"]:hover {
color: #fff;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
.deferment_text{
margin-bottom: 30px;
font-size: 12px;
color: #D62B2B;
text-align: right;
}

.support_links .link {
  margin: 0 auto 16px;
  text-align: center;
  text-decoration: none;
}
.support_links .link .link_ptn01 {
  font-size: 16px;
  color: #168AC7;
  text-decoration: underline;
  display:inline;
}

.support_links .link .link_ptn01:hover {
  color: #fff;
  background: #168AC7;
  text-decoration: none;
}

.messagebox{
/*display: none;*/
border: 1px solid #168AC7;
border-radius: 5px;
margin: 20px 0 0;
padding: 15px 10px;
font-size: 16px;
font-weight: 400;
color: #168AC7;
width: 100%;
background-color: #fff;
z-index: 15;
animation-name: message;
animation-duration: 1.5s;
animation-timing-function: ease;
animation-fill-mode: forwards;
}

@keyframes message{
  0%{
    opacity: 0;
  }
  100%{
    transform: translateY(-5px);
    opacity: 1;
  }
}

.support_links .messagebox {
  text-align: center;
  margin: 20px auto 14px;
}

/*--------------------
01_03.フォーム確認
--------------------*/
.signup_bg{
padding: 60px 0 150px;
}
.signup_bg::before{
content: '';
display: block;
width: 100%;
height: 100%;
background: url(../img/common/bgimg02_1.png) no-repeat center top/100% auto;
background-position: 0 85px;
position: absolute;
top: 0;
right: 0;
z-index: -1;
}
.signup_bg::after{
content: '';
display: block;
/* background-color: #168AC7; */
height: 100%;
position: absolute;
width: 100%;
top: 0;
z-index: -2;
}
.mail_change_ptn02.signup_bg::before{
content: none;
}
.form_box_ptn02{
max-width: 476px;
margin: 0 auto 30px;
display: block;
background: #fff;
border: 1px solid #D1D1D1;
border-radius: 20px;
padding: 40px;
box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
}
.form_box_ptn02 h2 {
margin: 0 auto 30px;
font-size: 18px;
line-height: 27px;
text-align: center;
font-weight: 600;
}
.form_box_ptn02 dt{
margin: 0 0 15px;
font-size: 15px;
font-weight: 500;
line-height: 21px;
}
.form_box_ptn02 dd{
margin: 0 0 35px;
padding: 0 0 0 1em;
font-size: 15px;
font-weight: 400;
line-height: 21px;
}
.pas {
/* display: flex;
align-items: center; */
position: relative;
margin: 0 0 5px;
border-radius: 3px;
background: #F7F7F7;
border: 1px solid #D1D1D1;
width: 100%;
font-weight: 400;
font-family: 'Noto Sans JP', sans-serif;
font-size: 16px;
height: 48px;
}
#input_pass,
#input_pass2{
margin: 0;
border: none;
padding: 13px 0 13px 16px;
background: rgba(255,255,255,0);
outline: none;
width: 100%;
font-size: 16px;
height: 48px;
}

#input_pass:focus,
#input_pass2:focus{
background: #fff;
border: 1px solid #168AC7;
}
#input_pass:focus::after{
content: '';
display: block;
position: absolute;
width: 100%;
height: 100%;
background: #fff;
border: 1px solid #168AC7;
}
#btn_passview,
#btn_passview2{
position: absolute;
top: 50%;
right: 15px;
transform: translateY(-50%);
width: 23px;
height: 15px;
/*background-image: url(../img/common/pas_icon2.png);*/
background-size: contain;
background-repeat: no-repeat;
}
input[type="password"] + #btn_passview,
input[type="password"] + #btn_passview2{
width: 23px;
height: 15px;
/* background-image: url(../img/common/pas_icon.png); */
background-size: contain;
background-repeat: no-repeat;
}

.form_box_ptn02 span{
display: block;
/*margin: 10px 0 0;*/
}
.form_box_ptn02 .privacy_box{
margin: 0 0 30px;
}
.form_box_ptn02 .checkbox{
display: flex;
align-items: center;
}
.form_box_ptn02 .checkbox::before{
content: '';
display: block;
margin: 0 13px 0 0;
background-image: url(../img/common/check.png);
background-size: cover;
background-repeat: no-repeat;
width: 11px;
height: 8px;
}
.form_box_ptn02 .btn_submit{
max-width: 320px;
width: 100%;
}
/*--------------------
01_04.背景色
--------------------*/
.bgc_primary{
background-color: #168AC7;
}
.bgc_secondary{
background-color: #ffffff;
}
.bgc_blue{
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
.bgc_red{
background-color: #D62B2B;
}
.bgc_gray{
background-color: #4A4A4A;
}
/*----------
02.ヘッダー
----------*/
header{
background: #fff;
box-shadow: 0 0 10px rgb(0 0 0 / 16%);
position: relative;
z-index: 5;
position: fixed;
top: 0;
left: 0;
width:100%;
}

header .logo{
max-width: 75.65px;
}

header .header_left{
display: flex;
align-items: center;
}

header .header_left span{
display: block;
color: #5F5F5F;
font-size: 14px;
font-weight: 500;
line-height: 20px;
margin-left: 22.6px;
}
header .logo a{
font-size: 24px;
font-weight: 700;
color: #168AC7;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
header .contents_in{
display: flex;
justify-content: space-between;
align-items: center;
padding: 17px 0;
width: 90.6%;
max-width: none;
}

header .header_right{
display: flex;
justify-content: flex-end;
}
header .header_right .btn_ptn02,
header .header_right .btn_ptn03{
margin-left: 15px;
width: 136px;
}
/*ログイン前*/
header.public .header_right{
max-width: 288px;
width: 100%;
}

header.public .header_right .btn_ptn03{
margin-left: 15px;
}

header.public .header_right .btn_ptn02 a,
header.public .header_right .btn_ptn03 a{
padding: 15px 0;
}



/*ログイン後*/

header.login .contents_in{
padding: 18px 0;
}
header.login .header_right li.btn{
width: 44px;
height: 44px;
display: block;
border-radius: 50%;
box-shadow: 0 0 10px rgb(0 0 0 / 16%);
margin: 0 0 0 19px;
}
header.login .header_right li.info a{
display: block;
position: relative;
width: 100%;
height: 100%;
}
header.login .header_right li.info a::before{
content: '';
display: block;
position: absolute;
width: 100%;
height: 100%;
background-image: url(../img/common/info_mark.png);
background-size: 14px 17px;
background-repeat: no-repeat;
background-position: center;
z-index: 1;
}
header.login .header_right li.info:hover{
background-color: rgb(13,87,160);
background: #E2F5FF;
border: none;
}
header.login .header_right li.info:hover a::before{
background-image: url(../img/common/info_mark.png);
}
header.login .header_right li.help a{
background-color: #fff;
border: 1px solid #E0E0E0;
color: #1584C2;
font-weight: 700;
font-size: 21px;
line-height: 40px;
display: block;
width: 100%;
text-align: center;
border-radius: 50%;
padding: 0 0 2px;
box-sizing: border-box;
}
header.login .header_right li.help a:hover{
color: #168AC7;
background: #E2F5FF;
border: 1px solid #E2F5FF;
}

header.login .header_right li.account{
position: relative;
}
header.login .header_right li.account span{
background: rgb(13,87,160);
background: url("../img/common/icon02_account.png") no-repeat center /15px auto, linear-gradient(308deg, rgba(13,87,160,1) 0%, rgba(22,138,199,1) 100%);
display: block;
width: 44px;
height: 44px;
border-radius: 50%;
box-sizing: border-box;
cursor: pointer;
}
header.login .header_right li.account span:hover{
background-color: #168AC7;
background: url("../img/common/icon02_account.png") no-repeat center /15px auto #168AC7;
border: 1px solid #5DB4E2;
}

header.login .account_nav{
display: none;
position: absolute;
top: calc(100% + 8px);
right: 0;
width: 250px;
background: #fff;
border-radius: 10px;
box-shadow: 0 0 10px rgb(0 0 0 / 16%);
padding: 20px 20px 4px;
}

/* 追加した部分 ログイン ここから */
header.login .account_nav_vis{
display: block;
position: absolute;
top: calc(100% + 8px);
right: 0;
width: 250px;
background: #fff;
border-radius: 10px;
box-shadow: 0 0 10px rgb(0 0 0 / 16%);
padding: 20px 20px 4px;
}

header.login li.account .account_nav_vis .account_name a{
display: flex;
justify-content: flex-start;
align-items: center;
font-size: 15px;
margin-bottom: 14px;
}

header.login li.account .account_nav_vis .account_name .icon{
width: 30px;
border-radius: 50%;
margin-right: 10px;
}

header.login li.account .account_nav_vis li{
display: block;
line-height: 22px;
padding: 0 0 10px;
}
header.login li.account .account_nav_vis li.bignav{
margin-bottom: 12px;
border-bottom: 1px solid #D1D1D1;
font-weight: 700;
font-size: 15px;
}
header.login li.account .account_nav_vis li.bignav:last-of-type{
border: none;
margin-bottom: 0;
}
header.login li.account .account_nav_vis li.bignav .smallnav{
padding-top: 14px;
}
header.login li.account .account_nav_vis li.bignav .smallnav li{
font-size: 14px;
font-weight: 400;
}
/* 追加した部分 ログイン ここまで */

header .info_area{
display: none;
position: absolute;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.6);
z-index: 10;
top: 0;
left: 0;
}
header .info_box{
position: absolute;
border: 1px solid #D1D1D1;
border-radius: 20px;
padding: 60px;
background-color: #fff;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
width: 95%;
max-width: 860px;
}
header .info_box .close{
position: absolute;
background: url(../img/common/close_w.png) no-repeat center /15px auto, linear-gradient(308deg, rgba(13,87,160,1) 0%, rgba(22,138,199,1) 100%);
width: 44px;
height: 44px;
display: block;
border-radius: 50%;
box-shadow: 0 0 10px rgb(0 0 0 / 16%);
top: 40px;
right: 40px;
}
header .info_box .close:hover{
background: url(../img/common/close.png) no-repeat center /15px auto, #fff;
}
header .info_text{
display: flex;
align-items: center;
margin: 0 auto 20px;
font-size: 22px;
font-weight: 700;
letter-spacing: 0.05em;
color: #168AC7;
}
header .info_text::before{
content: '';
display: block;
margin: 0 10px 0 0;
width: 40px;
height: 37px;
background-image: url(../img/common/info_parts.png);
background-repeat: no-repeat;
background-size: contain;
}
header .info_box dl{
display: flex;
flex-wrap: wrap;
position: relative;
padding: 20px 0 0;
max-height: 450px;
overflow-y: scroll;
-ms-overflow-style: none;
scrollbar-width: none;
}
header .info_box dl::-webkit-scrollbar {
/*display:none;*/
width: 10px;
height: 10px;
}

header .info_box dl::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #D1D1D2;
}
header .info_box dl::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

header .info_box dl::before{
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 1px;
background-image: linear-gradient(to right, #D5D5D5, #D5D5D5 4px, transparent 4px, transparent 8px);
background-size: 10px 1px;
background-repeat: repeat-x;
}
header .info_box dd{
position: relative;
margin: 0 0 20px;
padding: 0 0 20px;
width: 14%;
font-size: 14px;
font-weight: 500;
}
header .info_box dt{
position: relative;
margin: 0 0 20px;
padding: 0 8px 20px 0;
width: 86%;
font-size: 16px;
line-height: 1.5;
font-weight: 400;
}
header .info_box dd::after,
header .info_box dt::after{
content: "";
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 1px;
background-image: linear-gradient(to right, #D5D5D5, #D5D5D5 4px, transparent 4px, transparent 8px);
background-size: 10px 1px;
background-repeat: repeat-x;
}

/*リンクなしヘッダー*/
header.no_links .contents_in{
padding: 31px 0;
}
/*----------
03.フッター
----------*/



footer{
border-top: 1px solid #D1D1D1;
padding: 50px 0 20px;
background: #fff;
/* position: relative; */
/*z-index: 0;*/
z-index: 2;

position: absolute;
width: 100%;
bottom: 0;
left: 0;
text-align: center;
}


footer .footer_top{
display: flex;
flex-wrap: wrap;
align-items: center;
margin: 0 0 34px;
}
footer .logo{
margin: 0 93px 0 0;
max-width: 82.7px;
}
footer .logo a{
font-size: 26px;
font-weight: 700;
color: #168AC7;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
/*
footer nav{
display: flex;
flex-direction: column;
justify-content: center;
}
*/
footer .footer_nav{
display: flex;
justify-content: center;
}
footer .footer_nav li{
position: relative;
margin: 0 15px 0;
}
footer .footer_nav li:first-of-type{
margin: 0 15px 0 0;
}
footer .footer_nav li::after{
content: '';
display: block;
position: absolute;
height: 13px;
width: 1px;
background-color: #ACACAC;
right: -15px;
top: 50%;
transform: translateY(-50%);
}
footer .footer_nav li:last-of-type::after{
content: none;
}
footer .footer_nav a{
display: flex;
font-size: 14px;
font-weight: 400;
letter-spacing: 0.03em;
color: #4A4A4A;
}
footer .copyright{
width: 100%;
font-size: 12px;
font-weight: 400;
color: #B1B1B1;
}
.footer_fadein{
opacity: 0;
transition: 1.3s;
}

.footer_fadein.in{
opacity: 1;
}
footer.animate__animated{
opacity: 0;
}

/*リンクなしフッター*/
footer.no_links .logo{
margin-left: 0;
}
/*--------------------
04.ログイン後 サイドメニュー
--------------------*/

.setting_page{
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}

.setting_page .page_contents{
width: 100%;
padding: 12px 0 0;

}

.setting_page .side_nav{
max-width: 320px;
width: 100%;
padding: 61px 0 0;
position: fixed;
top: 80px;
left: 0;

height: 100%;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
}
.setting_page .side_nav h1{
display: block;
color: #fff;
font-weight: 700;
font-size: 24px;
line-height: 36px;
padding: 0 0 0 76px;
margin-bottom: 16px;
}
.setting_page .side_nav ul{
display: block;
position: relative;
padding-left: 17px;
margin-bottom: 25px;
}
.setting_page .side_nav ul::after{
content: '';
display: block;
width: 89.3%;
height: 1px;
background: #FFFFFF;
opacity: 0.5;
}
.setting_page .side_nav ul li{
margin-bottom: 6px;
}
.setting_page .side_nav ul li:hover,
.setting_page .side_nav ul li.active{
display: block;
background: rgb(0 0 0 / 20%);
border-radius: 10px 0 0 10px;
}
.setting_page .side_nav ul li a{
display: block;
color: #fff;
font-size: 18px;
line-height: 27px;
padding: 15px 0 16px 59px;
}
.setting_page .side_nav .btn_top a{
color: #fff;
font-size: 18px;
padding: 0 0 0 76px;
}
.setting_page .main_contents{
background: #fff;
border-radius: 40px 0 0 0;
width: calc(100% - 320px);
padding: 46px 0 80px;
margin: 0 0 0 auto;
position: relative;
}
.setting_page .main_contents .contents_in{
/*max-width: 842px;*/
width: 87.7%;
}
.setting_page .main_contents h2.h2_ptn01{
font-weight: 700;
font-size: 18px;
line-height: 27px;
margin-bottom: 20px;
}
.setting_page .main_contents .line{
width: 100%;
margin: 30px 0 20px;
border-bottom: 1px solid #D3D3D3;
display: block;
}

.setting_page .btn_edit {
display: inline-block;
}
.setting_page .btn_edit a{
color: #168AC7;
text-decoration: underline;
font-size: 14px;
cursor: pointer;
}
.setting_page .btn_edit a::after{
content: '';
display: inline-block;
background: url("../img/setting/icon01_edit.png") no-repeat center/contain;
width: 14px;
height: 14px;
margin: 0 0 0 6px;
vertical-align: middle;
}
.setting_page .save {
  max-width: 700px;
  }
  .setting_page .save.error{
  border: 1px solid #D62B2B;
  color: #D62B2B;
}

.modal{
display: none;
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.6);
z-index: 100;
}
.modal .page_contents{
margin: 0;
}
.modal .text_box_ptn01{
position: absolute;
margin: 0;
width: 94%;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}
.modal .text_box_ptn01 .close{
position: absolute;
border-radius: 90%;
height: 44px;
width: 44px;
background: linear-gradient(308deg, rgba(13,87,160,1) 0%, rgba(22,138,199,1) 100%);
top: 20px;
right: 4.2%;
}
.modal .text_box_ptn01 .close::before,
.modal .text_box_ptn01 .close::after{
content: '';
display: block;
position: absolute;
top: 50%;
left: 50%;
background-color: #fff;
width: 20px;
height: 2px;
transform: translate(-50%,-50%) rotate(45deg);
}
.modal .text_box_ptn01 .close::after{
transform: translate(-50%,-50%) rotate(-45deg);
}
.modal .text_box_ptn01 .title{
padding-left: 4.2%;
margin-bottom: 30px;
}

@media only screen and (max-width:1024px){
/*----------
01.共通
----------*/
.contents_in {
width: 94%;
}
.btn_ptn03.google a,
.btn_ptn03.twitter a{
font-size: 12px;
}

/*----------
03.フッター 1024
----------*/
footer .footer_top{
display: block;
}
footer .logo{
margin: 0 auto 20px;
text-align: center;
}


/*--------------------
04.ログイン後 サイドメニュー
--------------------*/

.setting_page .side_nav{
width: 240px;
}

.setting_page .main_contents{
width: calc(100% - 240px);
}
.setting_page .side_nav h1{
font-size: 22px;
padding: 0px 0 0 40px;
}
.setting_page .side_nav ul li a{
font-size: 16px;
line-height: 24px;
padding: 12px 0 13px 25px;
}
.setting_page .side_nav .btn_top a{
font-size: 16px;
padding: 0 0 0 40px;
}

/*--------------------
04.モーダル
--------------------*/
.modal{
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.8);
}


}

@media only screen and (max-width:750px){

/*----------
01.共通
----------*/
body{
padding-bottom: 296px;
}
.contents_in {
width: 92%;
}

.pc{
display: none;
}
.sp{
display: block;
}

.page_contents{
margin-top: 62px;
}

.footer_fadein{
opacity: 1;
}
footer.animate__animated{
opacity: 1;
}
/*----------
01_01.ボタン・本文
----------*/
.page_title_ptn01 p{
max-width: 476px;
margin: 0 auto;
padding: 0 20px;
}

/*----------
クッキー
----------*/
#cookie .cnt_wrap{
padding: 20px 15px;
}
#cookie .cnt_text{
margin-bottom: 20px;
}
#cookie .cnt_text p{
font-size: 12px;
line-height: 22px;
}
/*
#cookie .btn_box{

}
*/
#cookie .btn_submit{
margin: 0 auto;
max-width: 130px;
}
#cookie .btn_submit button,
.btn_submit input[type="submit"]{
font-size: 12px;
padding: 10px;
}
/*--------------------
01_01.ボタン・本文
--------------------*/

.btn_ptn01 a{
padding: 24px 50px 24px 0;
font-size: 16px;
}
.btn_ptn01 a::after{
width: 48px;
}
.btn_ptn01 a::before{
right: 22px;
}

.btn_ptn03 a{
font-size: 15px;
}

.page_title_ptn01 h1{
font-size: 28px;
line-height: 38px;
}
.text_box_ptn01{
padding: 30px 20px;
}

.headline_l{
font-size: 40px;
}
.headline_s{
font-size: 32px;
}
.title{
font-size: 28px;
}
.sub_title{
font-size: 20px;
}
.body{
font-size: 14px;
line-height: 26px;
}

/*--------------------
01_02.フォーム
--------------------*/
input[type="text"], input[type="tel"], input[type="password"], input[type="email"]{
font-size: 14px;
}
.checkbox{
font-size: 15px;
}
select{
font-size: 14px;
}
.btn_submit button, .btn_submit input[type="submit"]{
font-size: 15px;
}

textarea {
height: 140px;
}

/*--------------------
01_03.フォーム確認
--------------------*/
.signup_bg{
padding: 60px 0 85px;
background-color: #168AC7;
}
.signup_bg::before{
background: url(../img/common/bgimg03.png) no-repeat center/100% auto;
width: 120%;
height: 662px;
max-width: 584px;
margin: 0;
position: fixed;
z-index: 1;
top: 80px;
left: -24%;

position: absolute;
top: auto;
bottom: 0;
width: 124%;
}

.signup_bg::after {
background: #168AC7;
}

.signup_bg main{
position: relative;
z-index: 2;
}
.form_box_ptn02 {
padding: 30px 20px;
}
.form_box_ptn02 dd{
padding: 0;
}
/*----------
02.ヘッダー
----------*/
header .contents_in{
padding: 9px 0;
/*width: 94%;*/
width: 89.33%;
}
header .header_right .btn_ptn02,
header .header_right .btn_ptn03 {
margin-left: 15px;
width: 94px;
}
header .btn_ptn02 a,
header .btn_ptn03 a{
max-width: 96px;
font-size: 14px;
}
/*ログイン前*/

header.public .btn_ptn02 a,
header.public .btn_ptn03 a{
max-width: 100px;
padding: 11px 0;
}
header.public .header_right {
max-width: 211px;
width: 59.9%;
}

header.public .header_right .btn_ptn03 {
margin-left: 11px;
}

/*ログイン後*/
header.login .contents_in{
padding: 10px 0;
}
header .info_box{
padding: 30px;
}
header .info_box .close{
top: 15px;
right: 15px;
background-size: 11px auto;
width: 30px;
height: 30px;
}
header .info_box dd{
margin: 0 0 15px;
padding: 0;
width: 100%;
}
header .info_box dd::after{
content: none;
}
header .info_box dt{
width: 100%;
}

header .header_left span{
display: none;
}

/*リンクなしヘッダー*/
header.no_links .contents_in{
padding: 22.595px 0;
}
/*----------
03.フッター
----------*/

footer .contents_in{
width: 100%;
}
footer .footer_nav{
flex-flow: wrap;
justify-content: flex-start;
max-width: none;
width: 100%;
margin: 0 auto;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;
}
footer .footer_nav li,
footer .footer_nav li:first-of-type{
width: 50%;
margin: 0;
border-bottom: 1px solid #ddd;
}
footer .footer_nav li:nth-of-type(odd){
border-right: 1px solid #ddd;
}
footer .footer_nav li:last-of-type{
border-bottom: none;
}
footer .footer_nav li a{
display: block;
width: 100%;
padding: 15px 0 14px;
text-align: center;
}
footer .footer_nav li::after{
display: none;
}
footer .copyright{
text-align: center;
padding: 0 13px;
line-height: 18px;
}

/*リンクなしフッター*/
footer.no_links .logo{
margin-left: auto;
}

/*--------------------
04.ログイン後 サイドメニュー
--------------------*/
.setting_page{
  padding-bottom: 40px;
}
.setting_page .page_contents{
padding: 30px 0 0;
/*background: #fff;*/
}
.setting_page .main_contents{
width: 92%;
margin: 0 auto;
border-radius: 30px;
padding: 32px 0 120px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.setting_page h1.sp{
color: #fff;
font-size: 20px;
font-weight: 700;
text-align: center;
margin-bottom: 20px;
}
.setting_page .main_contents .contents_in{
width: 92%;
}

.setting_page .btn_open{
width: 44px;
height: 42px;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
border-radius: 0 8px 8px 0;
position: fixed;
top: 78px;
left: 0;
z-index: 2;
padding: 8px 0;

border-radius: 90%;
left: 20px;
background:#fff;
}
.setting_page .btn_open span{
display: block;
width: 24px;
height: 2px;
background: #FFF;
margin: 5px auto;


background: #1584C2;
}

.setting_page .btn_close{
width: 44px;
height: 44px;
background: #fff;
border-radius: 50%;
margin: 30px auto;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
position: relative;

position: absolute;
margin: 0;
top: 15px;
right: 15px;
}
.setting_page .btn_close::before,
.setting_page .btn_close::after{
content: '';
display: block;
width: 2px;
height: 24px;
background: #1584C2;
position: absolute;
top: 50%;
left: 0;
right: 0;
margin: 0 auto;
transform-origin: center;
}
.setting_page .btn_close::before{
transform: translateY(-50%) rotate(45deg);
}
.setting_page .btn_close::after{
transform: translateY(-50%) rotate(-45deg);
}

.setting_page .side_nav{
width: 208px;
height: calc(100vh - 64px);
z-index: 4;
background: transparent linear-gradient(180deg, rgba(22, 138, 199, 1) 0%, rgba(13, 87, 160, 1) 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
top: 64px;
left: -210px;
transition: .4s ease;
padding: 20px 0 0;

padding: 80px 0 0;
}
.setting_page .side_nav.show{
left: 0;
}
.setting_page .page_contents.act::after{
content: '';
display: block;
position: absolute;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.3);
top: 0;
right: 0;
z-index: 3;
}
.setting_page .side_nav h1{
font-size: 18px;
}
.setting_page .side_nav ul li a{
font-size: 14px;
line-height: 18px;
}
.setting_page .side_nav .btn_top a{
font-size: 14px;
}


}

@media only screen and (max-width:325px){
.btn_ptn03.google a::after{
left: 6.2%;
}
.btn_ptn03.google a::before{
left: 9.75%;
}
.btn_ptn03.twitter a::before{
left: 8.75%;
}
}
