/* ローディングCSS */

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.loader-1 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 10px;
    border-color: #0d57a0 #00000010 #00000010;
    position: fixed;
    top: 40%;
    left: auto;
    z-index: 10;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes stagger {
    25% {
        border-color: #00000010 #000000 #00000010 #00000010;
    }
    50% {
        border-color: #00000010 #00000010 #000000 #00000010;
    }
    75% {
        border-color: #00000010 #00000010 #00000010 #000000;
    }
}