@charset "UTF-8";
/* CSS Document */

/*--------------------
00.top
01.login
02.signup
8_入力内容確認
16.アカウント情報
17.ユーザー情報
30.サービス利用状況
41.プライバシーポリシー
42.利用規約
43.クッキーポリシー
44.問い合わせフォーム
45.問い合わせフォーム（確認画面）
46.問い合わせフォーム（完了画面）
新規会員登録 - アカウント登録
新規会員登録 - アカウント登録（メールアドレスで登録）
新規会員登録 - アカウント登録（SNSで登録）
ログイン画面 – パスワード不明
ログイン画面 – パスワード再設定
ログイン画面 – パスワード再設定完了
2段階認証（コード入力）
2段階認証済み
メールアドレス変更（確認完了）
アカウント情報 - セキュリティ
--------------------*/
@media screen and (min-width:1600px){
/*----------
00.top
----------*/
#top .bg{
background-image: url("../img/top/mv02.png");
background-repeat: no-repeat;
background-size: 106.62%;
background-position: -26.8px 71.5px;
}

#top .cnt_area00 .contents_in{
max-width: 1338px;
}
}

#top .cnt_area00{
padding: 462px 0 694px;
padding: 36vw 0 54vw;
}

#top .cnt_area00 .lead{
font-size: 14px;
line-height: 32px;
letter-spacing: 0.03em;
max-width: 446px;
color: #4A4A4A;
margin-bottom: 26px;
}

#top .cnt_area01 {
padding: 8.3vw 0 8.36vw;
position: relative;
}

#top .cnt_area01::after{
content: "";
display: block;
width: 100%;
height: 100%;
background-color: #168AC7;
position: absolute;
top: 0;
z-index: -1;
}

#top .cnt_area01 .contents_in{
max-width: 1280px;
position: relative;
justify-content: flex-end;
display: flex;
width: 100%;
}



#top .cnt_illust02{
max-width: 121.41px;
width: 14.11%;
position: absolute;
bottom: -200.23px;
bottom: -27.9%;
right: 36.5%;
}

#top .cnt_area01 .title{
color: #fff;
line-height: 54px;
letter-spacing: 0.05em;
padding-bottom: 22.8px;
margin-bottom: 55.8px;
position: relative;
}

#top .cnt_area01 .cnt_left{
max-width: 471px;
width: 36.79%;
margin-right: 65px;
}

#top .cnt_area01 .cnt_right{
max-width: 528px;
width: 41.25%;
}

#top .cnt_area01 .cnt_right img{
box-shadow: 0 0 15px rgba(101,222,224,0.15);
}

#top .title::after{
content: "";
display: block;
width: 55px;
height: 2px;
background-color: #fff;
position: absolute;
bottom: 0;
left: 0;
}

#top .cnt_area01 .body{
max-width: 594px;
font-size: 14px;
line-height: 35px;
letter-spacing: 0.03em;
color: #fff;
margin-bottom: 38px;
}

#top .cnt_news .contents_in{
max-width: 860px;
padding: 6.17vw 0 9.617vw;
}

#top .cnt_news .news_box{
display: flex;
padding: 24px 28.5px;
position: relative;
}

#top .cnt_news .news_box::before{
content: "";
display: block;
width: 100%;
height: 1px;
background-image: linear-gradient(to right, #D5D5D5, #D5D5D5 4px, transparent 4px, transparent 8px);
background-size: 8px 1px;
background-repeat: repeat-x;
position: absolute;
top: 0;
left: 0;
}

#top .cnt_news .news_box:last-child::after{
content: "";
display: block;
width: 100%;
height: 1px;
background-image: linear-gradient(to right, #D5D5D5, #D5D5D5 4px, transparent 4px, transparent 8px);
background-size: 8px 1px;
background-repeat: repeat-x;
position: absolute;
bottom: 0;
left: 0;
}

#top .cnt_news .date{
font-size: 14px;
color: #6E6E6E;
letter-spacing: 0.05em;
line-height: 24px;
}

#top .cnt_news .news_text{
font-size: 16px;
color: #000000;
line-height: 24px;
max-width: 700px;
width: 81.39%;
margin-left: 16px;
}

#top .cnt_news .news_text a{
display: block;
width: 100%;
}

#top .cnt_area02{
padding: 0 0 9.67vw;
}

#top .cnt_area02 .cnt_illust01{
max-width: 201.05px;
position: absolute;
top: -3.67%;
right: 8.32%;
width: 23.37%;
}

#top .cnt_area02 .contents_in{
max-width: 860px;
position: relative;
}

#top .cnt_illust03{
max-width: 185.19px;
width: 21.53%;
position: absolute;
top: 0;
right: -5.5px;
}

#top .cnt_area02 .title{
line-height: 58px;
letter-spacing: 0.05em;
color: #168AC7;
margin-bottom: 77.5px;
padding-bottom: 22px;
}

#top .cnt_area02 .title{
line-height: 58px;
letter-spacing: 0.05em;
color: #168AC7;
margin-bottom: 77.5px;
padding-bottom: 22px;
position: relative;
}

#top .cnt_area02 .title::after{
content: "";
display: block;
width: 55px;
height: 2px;
background-color: #168AC7;
position: absolute;
bottom: 0;
left: 0;
}

#top .cnt_area02 .flex_box{
display: flex;
justify-content: space-between;
}
#top .cnt_area02 .sub_title{
line-height: 32px;
font-size: 22px;
color: #168AC7;
margin: -46px auto 25px;
text-align: center;
background-color: #FFFFFF;
padding: 22px 0;
max-width: 316px;
}

#top .cnt_area02 .cnt_logo01{
max-width: 184px;
margin: 21px auto 35.3px;
}

#top .cnt_area02 .cnt_logo02{
max-width: 201px;
margin: 21px auto 20.9px;
}

#top .cnt_area02 .lead span{
font-size: 14px;
font-weight: 700;
line-height: 20px;
display: block;
margin: 0 auto 9px;
color: #707070;
}

#top .cnt_area02 .lead{
font-size: 18px;
letter-spacing: 0.03em;
line-height: 28px;
text-align: center;
color: #1B85C7;
margin: 0 auto;
padding-bottom: 16.9px;
padding-top: 21.1px;
position: relative;
}

#top .cnt_area02 .lead::before, #top .cnt_area02 .lead::after{
content: "";
display: block;
width: 344px;
height: 1px;
background-color: #B9B9B9;
position: absolute;
}

#top .cnt_area02 .lead::before{
top: 0;
left: 0;
right: 0;
margin: auto;
}

#top .cnt_area02 .lead::after{
bottom: 0;
left: 0;
right: 0;
margin: auto;
}




#top .cnt_area02 .flex_in01, #top .cnt_area02 .flex_in02{
max-width: 414px;
border: 3px solid #D2DDE5;
border-radius: 20px;
width: 48.13%;
}

#top .cnt_area02 ul{
max-width: 291.5px;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin: 19.6px auto 11.5px;
font-size: 16px;
font-weight: 500;
letter-spacing: 0.03em;
line-height: 24px;
color: #3C3C3C;
}

#top .cnt_area02 li{
padding-left: 14px;
position: relative;
min-width: 129px;
margin-bottom: 13px;
}

#top .cnt_area02 .flex_in02 li{
min-width: 162px;
}

#top .cnt_area02 .flex_in02 ul{
max-width: 342.5px;
}

#top .cnt_area02 li::after{
content: "";
display: block;
width: 8px;
height: 8px;
border-radius: 50%;
background-color: #168AC7;
position: absolute;
left: 0;
top: 0;
bottom: 0;
margin: auto;
}



#top .cnt_area03{
padding: 0 0 4.72vw;
}

#top .cnt_area03 .contents_in{
max-width: 860px;
position: relative;
}

#top .cnt_area03 .title{
line-height: 58px;
letter-spacing: 0.05em;
color: #168AC7;
margin-bottom: 77.5px;
padding-bottom: 22px;
position: relative;
}

#top .cnt_area03 .title::after {
content: "";
display: block;
width: 55px;
height: 2px;
background-color: #168AC7;
position: absolute;
bottom: 0;
left: 0;
}

#top .cnt_area03 .flex_box{
margin-top: 39.8px;
display: flex;
justify-content: space-between;
width: 65.93%;
margin: 39.8px auto 50px;
}

#top .cnt_area03 .round{
width: 29.1%;
}

#top .cnt_area03 .body{
font-weight: 500;
letter-spacing: 0.3em;
line-height: 28px;
text-align: center;
}

#top .cnt_area04{
padding: 27.58vw 0 7.7vw;
background-image: url("../img/top/bg_01.jpg");
background-repeat: no-repeat;
background-size: 100% auto;
background-position: center top;
}



#top .cnt_area04 .contents_in{
background-color: #fff;
border-radius: 20px 20px 0 0;
padding-top: 52.9px;
position: relative;
z-index: 1;
}

#top .cnt_area04 .contents_in::before, #top .cnt_area04 .contents_in::after{
content: "";
display: block;
position: absolute;
background-repeat: no-repeat;
background-size: contain;
z-index: -1;
}

#top .cnt_area04 .contents_in::before{
background-image: url("../img/top/bg_02.png");
width: 676.48px;
height: 589.18px;
left: -34.16%;
bottom: -128.6px;
z-index: -1;
}

#top .cnt_area04 .contents_in::after{
background-image: url("../img/top/bg_03.png");
width: 611.17px;
height: 667.5px;
right: -36.48%;
bottom: -128.6px;
}

#top .cnt_area04 .title{
max-width: 541.6px;
margin: 0 auto 16.9px;
}

#top .cnt_area04 .body p{
font-weight: 500;
letter-spacing: 0.03em;
line-height: 28px;
color: #4A4A4A;
text-align: center;
}

#top .cnt_area04 .body{
margin-bottom: 187.5px;
position: relative;
}

#top .cnt_area04 .body::after{
content: "";
display: block;
background-image:url("../img/top/deco_01.png");
background-size: contain;
background-repeat: no-repeat;
width: 12px;
height: 121px;
position: absolute;
left: 0;
right: 0;
margin: auto;
bottom: -157px;
}

#top .cnt_area04 .sub_title{
text-align: center;
line-height: 35px;
letter-spacing: 0.05em;
color: #168AC7;
margin-bottom: 37.9px;
}

#top .cnt_area04 .btn_ptn01{
margin: 0 auto;
}

#top .cnt_area04 .cnt_img{
max-width: 249.59px;
width: 29.02%;
position: absolute;
left: 0;
right: 0;
margin: auto;
bottom: 14.6px;
}

#top .cnt_area04 .cnt_img span{
display: block;
font-size: 29px;
font-weight: 700;
line-height: 42px;
letter-spacing: 0.05em;
color: #168AC7;
text-align: center;
margin-bottom: 42.2px;
}

@media only screen and (max-width:1280px){
/*----------
25_ログイン後トップ
----------*/
#login_top .box_r .cnt_wrap{
margin: 0 0 0 30px;
max-width: 267px;
}
#login_top .cnt_rbox h2{
display: flex;
align-items: center;
margin: 0 0 0 30px;
font-size: 20px;
font-weight: 700;
letter-spacing: 0.05em;
line-height: 65px;
color: #168AC7;
max-width: 267px;
}

}

@media only screen and (max-width:1024px){
/*----------
00.top
----------*/
#top .bg {
background-position: center 90.2px;
}
#top .cnt_area02 .cnt_illust01 {
top: 8.33%;
width: 21.37%;
}
#top .cnt_area02 .lead::before, #top .cnt_area02 .lead::after{
width: 83.09%;
}

#top .cnt_area02 .lead{
font-size: 16px;
}

#top .cnt_area02 .flex_in02 .lead{
font-size: 15px;
}

#top .cnt_area02 .flex_in02 ul{
max-width: 291.5px;
}

#top .cnt_area04 .contents_in::after{
right: -46.48%;
}

#top .cnt_area04 .contents_in::before{
left: -44.16%;
}

#top .cnt_illust02{
bottom: -14.9%;
}

/*----------
01.login 1024
----------*/
#login .btn_ptn01 a{
font-size: 14px;
}
#login .box_l{
justify-content: space-around;
}

/*----------
02.signup 1024
----------*/
#signup::before{
height: 548px;
background: url("../img/common/bgimg02.png") no-repeat center top/1024px auto;
background-color: #168AC7;
}

/*--------------------
16.アカウント情報 1024
--------------------*/
#account_info .cnt_img{
position: absolute;
width: 107px;
bottom: 20px;
right: 20px;
}
/*----------
25_ログイン後トップ
----------*/
#first_login .popup{
padding: 7vh 40px;
}
#first_login h2{
font-size: 32px;
line-height: 32px;
margin-bottom: 16px;
}
#first_login p{
font-size: 14px;
line-height: 24px;
margin-bottom: 20px;
}
#first_login .box{
height: calc(100% - 116px);
}



#login_top .r_box01 .service{
margin: 0 0 5px 5px;
}
#login_top .cnt_rbox{
padding-right: 10px;
padding-left: 10px;
}
#login_top .cnt_rbox h2,
#login_top .box_r .cnt_wrap{
margin-left: 0;
}
#login_top .g02 .cnt_text{
padding-left: 15px;
font-size: 16px;
line-height: 26px;
}



#security .flex_box{
display: block;
}
#security .flex_box .btn_edit{
margin: 30px 28px 0 0;
}
/*----------
ログイン履歴
----------*/
#login_history tr th:nth-of-type(1),
#login_history tr td:nth-of-type(1){
padding-left: 2%;
width: 25.3%;
}
#login_history tr th:nth-of-type(2),
#login_history tr td:nth-of-type(2){
padding-left: 2%;
width: 27.6%;
}
#login_history tr th:nth-of-type(3),
#login_history tr td:nth-of-type(3){
padding-left: 2%;
width: 23.4%;
}
#login_history tr th:nth-of-type(4),
#login_history tr td:nth-of-type(4){
padding-left: 2%;
width: 23.7%;
}
#mail_temporary_registration.signup_bg::before{
height: 65%;
}

#mail_temporary_registration.signup_bg::after{
height: 65%;
}
#sns_temporary_registration.signup_bg::before,
#sns_temporary_registration.signup_bg::after{
height: 66%;
}
#signup_confirm.signup_bg::before,
#signup_confirm.signup_bg::after{
height: 45%;
}
#password_forget.signup_bg::before,
#password_forget.signup_bg::after{
height: 61%;
}
}
@media only screen and (max-width:850px){
/*----------
25_ログイン後トップ
----------*/
#security .cnt_box04::after{
width: 50px;
height: 91px;
top: 20px;
right: 20px;
bottom: auto;
}
#login_top .g01 .link a{
font-size: 14px;
}
#login_top .g01 .data ul{
margin-bottom: 0;
}
#login_top .g01 h3{
font-size: 14px;
line-height: 24px;
}
#login_top .gray_box .service_text{
font-size: 22px;
line-height: 30px;
}
#login_top .gray_box li{
flex-direction: column;
}
#login_top .gray_box .cnt_img{
margin: 0 0 30px;
max-width: 150px;
width: 100%;
}
#login_top .gray_box .text_box{
padding: 0;
width: 100%;
}
#login_top .gray_box .btn a{
padding: 13px 10px;
font-size: 14px;
}
/*----------
ログイン履歴
----------*/
#login_history th{
font-size: 16px;
}
#login_history td{
font-size: 14px;
}
/*----------
アカウント情報 - セキュリティ
----------*/
#security #account_delete_done .title.title01{
font-size: 36px;
}
}
@media only screen and (max-width:750px){
/*----------
00.top
----------*/
#top .bg{
position: relative;
background-image: url(../img/top/mv01_sp.png);
background-size: 100% auto;
background-position: center 277px;
}
#top .bg::before{
content: '';
display: block;
position: absolute;
width: 420.53px;
height: 176.6px;
background-image: url(../img/top/sp_catch.png);
background-repeat: no-repeat;
background-size: contain;
top: 70.9px;
left: 40.6%;
transform: translateX(-50%);
}
#top .cnt_area00{
padding: 264px 0 240vw;
}
#top .cnt_area00 .contents_in{
width: calc(100% - 40px);
padding: 0 0 32.5px;
background-color: #ffffff;
}
#top .cnt_area00 .lead{
margin: 0 auto 35px;
text-align: center;
font-size: 12px;
line-height: 22px;
}
#top .cnt_area00 .btn_ptn01{
margin: 0 auto;
max-width: 246px;
}
#top .cnt_area00 .btn_ptn01 a{
padding: 19.5px 46px 19.5px 0;
font-size: 15px;
line-height: 21px;
letter-spacing: 0.05em;
}
#top .cnt_area01{
padding: 15.8vw 0 18.66vw;
}
#top .cnt_area01 .contents_in{
position: static;
width: calc(100% - 40px);
display: block;
}
#top .cnt_area01 .cnt_left {
width: 100%;
margin-right: 0;
max-width: none;
}

#top .cnt_area01 .cnt_right{
width: 100%;
max-width: 335px;
margin-bottom: 48.8px;
margin: 0 auto 48.8px;
}

#top .cnt_news .news_box{
display: block;
}

#top .cnt_news .contents_in {
padding: 17.32vw 0 17.32vw;
width: calc(100% - 40px);
}

#top .cnt_news .news_text{
margin-left: 0;
width: 100%;
font-size: 15px;
font-weight: 400;
line-height: 28px;
}
#top .cnt_area02 .cnt_illust01 {
width: 25.05%;
bottom: 2%;
right: auto;
left: 7.8%;
top: auto;
}

#top .cnt_area .title{
font-size: 30px;
line-height: 50px;
}
#top .cnt_area02 .img_title{
font-size: 28px;
line-height: 54px;
}
#top .cnt_area01 .body{
margin: 0 0 40px;
line-height: 28px;
}
#top .cnt_area01 .btn_ptn01{
margin: 0 auto 0 0;
max-width: 246px;
}
#top .cnt_area01 .btn_ptn01 a{
padding: 19.5px 46px 19.5px 0;
font-size: 15px;
letter-spacing: 0.05em;
line-height: 21px;
}
#top .cnt_illust02{
right: 5.33%;
bottom: -37.9px;
left: auto;
width: 16.78%;
}
#top .cnt_area02{
position: relative;
padding: 65px 0 170px;
padding: 0 0 45.4vw;
}
#top .cnt_area02 .contents_in{
position: static;
width: calc(100% - 40px);
}
#top .cnt_area02 .title{
margin: 0 0 81px;
}


#top .cnt_area02 .flex_in02{
margin-bottom: 0;
}

#top .cnt_area02 .flex_in01, #top .cnt_area02 .flex_in02{
width: 100%;
max-width: 334.58px;
margin: 0 auto 96px;
}

#top .cnt_area02 .flex_box{
display: block;
margin: 0;
}

#top .cnt_area02 .flex_in02{
margin-bottom: 0;
}


#top .cnt_area02 ul{
font-size: 14px;
line-height: 26px;
}

#top .cnt_area02 .text_g{
width: 100%;
max-width: none;
}
#top .cnt_area02 .sub_title{
margin: -46px auto 20px;
font-size: 24px;
line-height: 33px;
text-align: center;
max-width: 310px;
width: 83%;
}
#top .cnt_area02 .body{
margin: 0 0 30px;
text-align: center;
}
#top .cnt_area02 .btn_ptn01{
margin: 0 auto;
}
#top .cnt_area02 .flex_box02{
margin: 0;
}

#top .cnt_illust03 {
width: 30.42%;
bottom: 2%;
right: 0;
left: auto;
}

#top .cnt_area03 .contents_in{
width: calc(100% - 40px);
}


#top .cnt_area03 .title{
margin-bottom: 35px;
}

#top .cnt_area03 .body{
font-size: 14px;
line-height: 26px;
}

#top .cnt_area03{
margin-bottom: 12vw;
}

#top .cnt_area03 .flex_box{
width: 100%;
}

#top .cnt_area04{
padding: 143.5px 0 0;
padding: 38.2vw 0 0;
background-image: url("../img/top/bg_01_sp.jpg");
}
#top .cnt_area04 .contents_in{
width: calc(100% - 40px);
padding: 40px 20px 116px;
position: relative;
}



#top .cnt_area04 .title{
margin: 0 0 25px;
}
#top .cnt_area04 .flex_box{
display: block;
margin: 0 0 25px;
padding: 0;
}
#top .cnt_area04 .list_box{
margin: 0 auto;
padding: 15px 0;
border: 3px solid #3E89C2;
border-radius: 20px;
width: calc(100% - 40px);
}
#top .cnt_area04 .sub_title{
margin-bottom: 0;
}
#top .cnt_area04 .list_box ul{
border: none;
border-radius: 0;
padding: 0;
}
#top .cnt_area04 .cnt_img{
position: relative;
padding: 20px 0;
max-width: none;
width: 100%;
left: auto;
right: auto;
bottom: auto;
}
#top .cnt_area04 .cnt_img span{
margin: 0;
}
#top .cnt_area04 .cnt_img .span_img{
position: absolute;
width: 105px;
top: 50%;
left: 65%;
transform: translateY(-50%);
}
#top .cnt_area04 .sub_title{
margin: 0 0 20px;
font-size: 22px;
line-height: 35px;
}

#top .cnt_area04 .body p{
font-size: 15px;
letter-spacing: 0.03em;
line-height: 30px;
font-weight: 500;
}

#top .cnt_area04 .body{
margin-bottom: 127px;
}

#top .cnt_area04 .body::after{
bottom: -133px;
}

#top .cnt_area04 .contents_in{
background-image: url("../img/top/bg_02_sp.png");
background-repeat: no-repeat;
background-size: 100% auto;
background-position: center bottom;
}

#top .cnt_area04 .contents_in::before{
content: none;
}

#top .cnt_area04 .contents_in::after{
content: none;
}

#top .cnt_area04 .btn_ptn01{
max-width: 246px;
}
#top .cnt_area04 .btn_ptn01 a{
padding: 19.5px 46px 19.5px 0;
font-size: 15px;
line-height: 21px;
letter-spacing: 0.05em;
}

/*----------
01.login 750
----------*/
#login .cnt_box{
display: block;
padding: 60px 0 100px;
}
#login .box_l{
width: 100%;
margin: 0 auto 100px;
position: relative;
}
#login .box_l .text{
font-size: 32px;
margin: 0 0 60px;
}
#login .box_l .text{
max-width: 100px;
margin: 0 auto;
}
#login .box_l .cnt_img{
width: 120%;
max-width: 600px;
margin: 0;
position: fixed;
z-index: -1;
top: 80px;
left: -24%;
opacity: 0.5;
}
#login .btn_ptn01.pc{
display: none;
}
#login .btn_ptn01.sp{
display: flex;
margin: 0 auto;
}
#login .btn_ptn01.sp a{
line-height: 22px;
padding: 18px 48px 18px 0;
width: 100%;
}
#login .box_r{
width: 100%;
margin: 0 auto 60px;
}
#login .box_r .btn_ptn03.google a::before{
left: 10.3%;
}
#login .box_r .btn_ptn03.google a::after{
left: 7.2%;
}
#login .box_r .btn_ptn03.twitter a::before{
left: 9.3%;
}
/*----------
02.signup 750
----------*/
#signup {
padding: 60px 0 85px;
background-color: #168AC7;
margin-top: 63.6px;
}
#signup .page_title_ptn01.white h1 span{
max-width: 100px;
}
#signup::before{
background: url("../img/common/bgimg03.png") no-repeat center/100% auto;
width: 120%;
height: 662px;
max-width: 584px;
margin: 0;
position: fixed;
/*z-index: 1;*/
z-index: 0;
top: 150px;
left: -24%;
}
#signup::after{
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #168AC7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  }
#signup .text_box h2{
font-size: 16px;
}
#signup .text_box p{
margin-bottom: 20px;
font-size: 12px;
}
#signup .btn_g{
display: block;
margin-bottom: 0;
}
#signup .btn_g .btn_ptn01{
width: 100%;
margin: 0 auto 8px;
}
#signup .btn_g .btn_ptn01 a{
font-size: 14px;
}

#signup .cnt_area{
width: 100%;
padding: 0;
background: none;
}

#signup .cnt_area .text_box{
background: rgb(255 255 255 / 85%);
border-radius: 20px;
margin-bottom: 30px;
}
/*--------------------
8_入力内容確認 750
--------------------*/
#signup_confirm{
margin-top: 63.6px;
}

#signup_confirm .page_title_ptn01.white h1 span{
max-width: 100px;
}
#signup_confirm.signup_bg::before{
position: fixed;
height: 662px;
top: 160px;
}
#signup_confirm.signup_bg::after{
position: fixed;
height: 100%;
}

/*--------------------
25.ログイン後トップ 750
--------------------*/
#first_login .btn_submit input[type="submit"] {
  padding: 16px 0;
}

/*--------------------
16.アカウント情報 750
--------------------*/
#account_info .user_img p{
font-size: 13px;
line-height: 18px;
}
#account_info .btn_submit{
margin: 30px auto 20px;
}
#account_info .main_contents h2.h2_ptn01{
margin-bottom: 59px;
}
#account_info .cnt_img {
position: absolute;
width: 52px;
top: 20px;
right: 20px;
bottom: auto;
}


/*--------------------
17.ユーザー情報 750
--------------------*/

#user_info .main_contents{
height: auto;
}

#user_info .country_g dd {
width: 100%;
}

#user_info .country_g dd select {
max-width: none;
}

#user_info .btn_submit {
margin: 30px auto 20px;
}

#user_info .btn_submit input[type="submit"] {
padding: 16px 0;
}
#user_info  .main_contents h2.h2_ptn01{
margin-bottom: 60px;
}
#user_info .cnt_img {
position: absolute;
width: 42px;
top: 20px;
bottom: auto;
right: 20px;
}

/*----------------
30.サービス利用状況 750
-----------------*/

#status .cnt_g {
margin-bottom: 35px;
}

#status .cnt_g:nth-of-type(3){
margin-bottom: 30px;
}

#status h3 {
font-size: 16px;
line-height: 25px;
margin-bottom: 10px;
}

#status table th:nth-of-type(1){
padding: 8px 5px;
text-align: left;
width: 50%;
}

#status table .service th a{
font-size: 16px;
line-height: 24px;
}

#status table .item th:nth-of-type(2),
#status table td:nth-of-type(1){
width: 25%;
}

#status table td:nth-of-type(1){
font-size: 16px;
line-height: 24px;
padding: 8px 5px;
}

#status table .item th:nth-of-type(3),
#status table td:nth-of-type(2){
padding: 8px 0;
width: 25%;
}

#status .link a {
font-size: 14px;
line-height: 25px;
}

#status .cnt_img{
position: absolute;
width: 43px;
top: 20px;
right: 28px;
}

/*---------------------
41.プライバシーポリシー 750
----------------------*/

.public_page h1 {
font-size: 30px;
line-height: 40px;
}

/*ポリシーページ共通CSS*/
.policy_page p {
font-size: 14px;
line-height: 26px;
margin: 0 auto 30px;
}

.policy_page h2 {
font-size: 17px;
line-height: 25px;
margin: 0 auto 15px;
padding-bottom: 6px;
}

/*END　ポリシーページ共通CSS*/

/*------------------
42.利用規約 750
-------------------*/
/*------------------
43.クッキーポリシー 750
-------------------*/

/*-----------------------
44.問い合わせフォーム 750
------------------------*/
/*お問い合わせページ共通CSS*/

.contact_page .page_contents {
padding: 60px 0;
}


/*END お問い合わせページ共通CSS*/

#contact .text_box_ptn01{
margin: 30px auto 0;
padding: 30px 20px;
}
#contact .recaptcha{
transform: scale(0.85) translateX(-25px);
}

/*------------------------------
45.問い合わせフォーム（確認画面）750
-------------------------------*/

#contact_confirm .form_box_ptn02 {
margin: 30px auto 0;
padding: 30px 20px;
}




/*------------------------------
46.問い合わせフォーム（完了画面）750
-------------------------------*/

#contact_done .page_contents {
padding: 60px 0 150px;
}

#contact_done p {
margin-bottom: 40px;
}

#contact_done .lead {
margin-bottom: 40px;
}

#contact_done .cnt_img {
position: absolute;
width: 80px;
bottom: 10px;
right: 20px;
}

/*----------
25_ログイン後トップ
----------*/

#first_login .popup {
padding: 30px 14px 20px;
max-height: 580px;
width: 94%;
height: 85vh;
}
#first_login .box{
height: calc(100% - 160px);
padding: 8px 4px;
}
#first_login .box .inner::-webkit-scrollbar {
width: 6px;
}
#first_login .box .inner{
padding: 10px;
}
#first_login h2{
font-size: 22px;
line-height: 28px;
margin-bottom: 16px;
}
#first_login .btn_close{
top: 20px;
right: 14px;
}
#first_login .country_g .form_text span{
line-height: 20px;
font-size: 14px;
}



#login_top .side_btn{
position: fixed;
border-radius: 90%;
width: 44px;
height: 44px;
top: 78px;
right: 10px;
background-color: #fff;
box-shadow: 0px 0px 10px rgb(0,0,0,0.16);
z-index: 9;
}
#login_top .side_btn span{
display: block;
position: absolute;
width: 24px;
height: 2px;
background: #1584C2;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);

}
#login_top .side_btn span::before,
#login_top .side_btn span::after{
content: '';
display: block;
position: absolute;
width: 24px;
height: 2px;
top: -7px;
background: #1584C2;
transition: 0.5s;
}
#login_top .side_btn span::after{
top: 7px;
}
#login_top .side_btn.act span{
background-color: #fff;
}
#login_top .side_btn.act span::before{
top: 0;
transform: rotate(45deg);
}
#login_top .side_btn.act span::after{
top: 0;
transform: rotate(-45deg);
}
#login_top .box_r{
position: fixed;
top: 64px;
right: 0;
max-width: 375px;
width: 100%;
height: calc(100% - 64px);
transform: translateX(110%);
transition: 0.7s;
overflow-y: scroll;
z-index: 8;
}
#login_top .box_r.act{
transform: translateX(0);
}
#login_top .r_box02{
padding-bottom: 30px;
background-color: #fff;
}
#login_top .cnt_rbox{
padding-right: 15px;
padding-left: 15px;
}
#login_top .cnt_rbox h2,
#login_top .box_r .cnt_wrap{
max-width: none;
}
#login_top .cnt_outwrap{
display: block;
}
#login_top .main_box{
width: 100%;
}
/*----------
アカウント情報 - セキュリティ
----------*/
#security .delete a{
font-size: 16px;
}
#security #account_delete_done .title{
font-size: 24px;
line-height: 1.4;
}

#security #account_delete_done .title.title01{
font-size: 24px;
line-height: 1.4;
}
/*----------
ログイン履歴
----------*/
#login_history .cnt_top{
margin: 0 auto 30px;
}
#login_history .link{
margin: 0 0 15px;
}
#login_history .link .blue{
font-size: 12px;
}
#login_history .cnt_top .cnt_img{
width: 11%;
}
#login_history table{
margin: 0 auto 50px;
width: 570px;
}
#login_history .kome_text{
font-size: 12px;
}
#login_history .text_box_ptn01{
overflow-x: scroll;
}
#login_history th{
padding-bottom: 10px;
}
#login_history td{
padding: 10px 0 10px 2%;
}
#login_history tr th:nth-of-type(1),
#login_history tr td:nth-of-type(1){
width: 20%;
}
#login_history tr th:nth-of-type(3),
#login_history tr td:nth-of-type(3){
width: 20%;
}
#login_history tr th:nth-of-type(4),
#login_history tr td:nth-of-type(4){
width: 20%;
}
/*----------
ログイン後アカウント画面 - 通知
----------*/
#notification .checkbox_ptn02 label{
margin: 0 10px 10px 0;
}
#notification .checkbox label{
font-size: 14px;
}
#notification .main_contents h2.h2_ptn01{
margin-bottom: 60px;
}
#notification .cnt_img{
position: absolute;
width: 45px;
bottom: auto;
top: 10px;
right: 20px;
}
/*----------
ログイン後アカウント画面 - パスワード変更
----------*/
#new_password .mail_change .contents_in{
padding: 100px 0 0;
}
#new_password .form_box_ptn02{
/* margin-top: 90px; */
}
#new_password .decoration_img{
width: 70px;
bottom: -30px;
}
/*----------
新規会員登録 - アカウント登録
----------*/
#new_account.signup_bg::before{
top: auto;
}
#new_account{
margin-top: 63.6px;
}


#new_account .login_top_box{
margin: 0 auto 25px;
}
/*----------
新規会員登録 – メール送信
----------*/
#mail_send{
margin-top: 63.6px;
}

#mail_send .title{
font-size: 24px;
}
#mail_send .lead{
font-size: 14px;
}
#mail_send .cnt_text p{
font-size: 12px;
}
/*----------
メールアドレス変更（本人確認）
----------*/
.mail_change .title{
font-size: 26px;
}
.mail_change .lead{
font-size: 14px;
}
.form_box_ptn02 dt{
font-size: 13px;
}
.mail_change .decoration_img{
position: absolute;
width: 50px;
}
/*----------
ログイン後アカウント画面 - メールアドレス変更（メール送信）
----------*/
.mail_change_ptn02{
background-color: rgba(255, 255, 255, 0);
}
.mail_change_ptn02 .page_title_ptn01 h1{
font-size: 23px;
}
.mail_change_ptn02 p{
font-size: 12px;
}
.mail_change_ptn02 .decoration_img{
width: 50px;
right: -15px;
}
/*----------
ログイン後アカウント画面 - サービスの利用状況（使用開始）
----------*/
#status_start{
margin-top: 63.6px;
}
#status.setting_page .main_contents h2.h2_ptn01{
margin-bottom: 50px;
}
#status .lead{
font-weight: 400;
margin-bottom: 30px;
}
#status_start .together{
font-size: 14px;
}
#status_start .form_box_ptn02{
padding: 30px 10px;
}
/*----------
ログイン後アカウント画面 - サービスの利用状況（使用停止）
----------*/
#status_stop{
margin-top: 63.6px;
}
#status_stop .together{
font-size: 14px;
}
#status_stop .form_box_ptn02{
padding: 30px 10px;
}
/*----------
アカウントの削除
----------*/
#account_delete.mail_change .lead{
font-size: 12px;
}
/*----------
アカウント削除完了
----------*/
#account_delete_done.mail_change_ptn02 .page_title_ptn01 h1{
font-size: 18px;
}
#account_delete_done.mail_change_ptn02 .cnt_text p{
font-size: 11px;
}
/*----------
全エラー画面共通
----------*/
#error404 .btn_box{
display: block;
}
#error404 .btn_box .btn{
margin: 0 auto;
}
#error404 .btn_box .btn:first-of-type{
margin: 0 auto 30px;
}
/*----------
会社概要
----------*/
#company dl{
display: flex;
flex-wrap: wrap;
}
#company dt{
margin: 0;
padding: 0 0 0 10px;
border-bottom: none;
width: 100%;
}
#company dd{
padding: 0 0 20px 10px;
width: 100%;
}
#company dd p{
margin: 0 0 20px;
}
/*----------
新規会員登録 - アカウント登録
----------*/
#new_account .page_title_ptn01.white h1 span{
max-width: 100px;
}
/*----------
新規会員登録 - アカウント登録（メールアドレスで登録）
----------*/
#mail_temporary_registration .page_title_ptn01.white h1 span{
max-width: 100px;
}
#mail_temporary_registration{
margin-top: 63.6px;
}
#mail_temporary_registration.signup_bg::before,
#sns_temporary_registration.signup_bg::before{
height: 662px;
}
#mail_temporary_registration.signup_bg::after,
#sns_temporary_registration.signup_bg::after{
height: 100%;
}
/*----------
新規会員登録 - アカウント登録（SNSで登録）
----------*/
#sns_temporary_registration{
margin-top: 63.6px;
}

#sns_temporary_registration .page_title_ptn01.white h1 span{
max-width: 100px;
}
/*----------
ログイン画面 – パスワード再設定
----------*/


#password_reset{
margin-top: 63.6px;
}


/*----------
ログイン画面 – パスワード再設定完了
----------*/
#password_done{
margin-top: 63.6px;
}
/*----------
ログイン画面 – パスワード不明
----------*/
#password_forget{
margin-top: 63.6px;
}
#password_forget.signup_bg::before{
position: fixed;
height: 661px;
top: 150px;
}
#password_forget.signup_bg::after{
height: 100%;
}
/*----------
2段階認証（コード入力）
----------*/
#two_step_code.mail_change{
position: static;
margin: 80px auto;
transform: none;
}
/*----------
2段階認証済み
----------*/
#two_step_login{
margin-top: 63.6px;
}
/*----------
メールアドレス変更
----------*/
#new_mail .decoration_img{
width: 70px;
bottom: -30px;
}
/*----------
メールアドレス変更（確認完了）
----------*/
#mail_change_done{
margin-top: 63.6px;
}
#mail_change_done h1{
font-size: 26px;
line-height: 38px;
}
}

@media only screen and (max-width:500px){
#login_top .g01 .title_area{
display: block;
margin: 0 0 33px;
}
#login_top .g01 .cnt_box a{
flex-direction: column;
}
#login_top .g01 .cnt_img{
margin: 0 auto 15px;
width: 100%;
}
#login_top .g01 .data{
padding: 0;
width: 100%;
}
#login_top .g01 .tagempty_text {
  padding: 20px;
}
#login_top .g01 .tagempty_text .text {
  min-height: auto;
  /*margin:1em;*/
}
#login_top .g01 .tagempty_text .text p {
  font-size: 14px;
  line-height: 1.4;
}
#login_top .g01 .cnt_box .tagempty_text a{
flex-direction: inherit;
}
#login_top .g02 .cnt_box{
flex-direction: column;
}
#login_top .g02 .cnt_img{
width: 100%;
}
#login_top .g02 .cnt_img img{
border-radius: 15px 15px 0 0;
}
#login_top .g02 .cnt_text{
padding: 20px 10px;
width: 100%;
text-align: center;
font-size: 14px;
}
#login_top .gray_box li:last-of-type{
margin-bottom: 0;
border: none;
padding-bottom: 0;
}
.page_exit .text_box_ptn01 .title{
font-size: 24px;
}
.page_exit .text_box_ptn01 p {
  font-size: 14px;
}
.page_exit .btn_ptn02{
margin: 0 30px 0 0;
}
#account_info .bthacks_profile img{
margin-right: 6.75rem ;
}
}

@media screen and (max-width:376px){
#top .cnt_area00{
padding: 264px 0 219vw;
}
#account_info .bthacks_profile img{
margin-right: 3.75rem ;
}
}

@media only screen and (max-width:360px){
/*----------
00.top
----------*/
#top .bg::before{
width: 360px;
}

#top .cnt_area02 .title{
font-size: 28px;
}

#top .cnt_area02 .sub_title{
font-size: 20px;
}

#top .cnt_area02 ul{
max-width: 240px;
}

#top .cnt_area02 .flex_in02 ul{
max-width: 255px;
}

#top .cnt_area02 li{
font-size: 12px;
min-width: 110px;
}
#top .cnt_area02 .flex_in02 li{
min-width: 125px;
}

/*----------
01.login 360
----------*/
#login .recaptcha{
transform: scale(0.84);
transform-origin: left;
}


/*------------------------------
46.問い合わせフォーム（完了画面）360
-------------------------------*/
#contact_done .lead {
font-size: 14px;
}

#contact_done p{
font-size: 14px;
}

#account_info .bthacks_profile img{
margin-right: 2.75rem ;
}

}


@media only screen and (max-width:350px){
#login_top .gray_box .btn a{
font-size: 12px;
}
#password_confirm_mail .title{
font-size: 24px;
}
#password_confirm_mail .lead{
font-size: 14px;
}
#password_confirm_mail .cnt_text{
font-size: 12px;
}
/*----------
新規会員登録 – メール送信
----------*/
#mail_send .title{
font-size: 20px;
}
#mail_send .lead{
font-size: 12px;
}
#mail_send .cnt_text p{
font-size: 10px;
}
/*----------
アカウントの削除
----------*/
#account_delete.mail_change .contents_in{
padding: 100px 0 0;
}

}
@media only screen and (max-width:325px){
.recaptcha{
transform: scale(0.85);
}
#password_reset .page_title_ptn01 h1{
font-size: 24px;
}
header .header_right .btn_ptn02,
header .header_right .btn_ptn03{
margin-left: 5px;
}
#two_step_login .page_title_ptn01 h1{
font-size: 26px;
}
#login_history .text_box_ptn01 .table_box{
height: 350px;
}
#mail_change_done h1{
font-size: 20px;
}
}
